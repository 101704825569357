/* UTILITIES EXTRA */

@mixin opacity() {
  $opacity-list: map-values($opacity-map);

  @each $value in $opacity-list {
    $doc: "0.";
    .u-opacity-#{$value} {
      opacity: #{$doc}#{$value};
    }
  }
}

@include opacity();

.u-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  &--line {
    @extend .u-ellipsis;
    -webkit-line-clamp: 1;
  }

}

.u-state {
  &-disable {
    opacity: 0.5;
  }
}


.u-flex-fill {
  flex: 1 1 auto;
}

@mixin flex-grow-shrink() {
  $cases: (
    0, 1
  );


  @each $value in $cases {
    .u-flex-grow-#{$value} {
      flex-grow: $value;
    }

    .u-flex-shrink-#{$value} {
      flex-shrink: $value;
    }
  }

}

@include flex-grow-shrink();




@mixin text-indent-utility {
  $text-indent-map: (
    1 : 0.5rem,
    2 : 1rem,
    3 : 1.5rem,
    4 : 2rem
  );


  $text-indent-keys: map-keys($text-indent-map);

  @each $key in $text-indent-keys {
    .u-indent-#{$key} {
      $property: text-indent;
      $value: map-get($text-indent-map, $key);

      #{$property} : $value;

      @include sufixes($property, $value);

    }
  }

}

@include text-indent-utility();

.u-size-content\@xl {
  @include min-width ($bp-xl) {
    max-width: fit-content;
    width: fit-content;
  }
}

@mixin gap-utility {

  $gap: ( 2, 4, 8, 12, 14, 16, 24 );

  @each $item in $gap {
      .u-gap-#{$item} {
          gap: calcrem($item);
      }
  }
}

@include gap-utility;

.u-rotate-180 {
  transform: rotate(180deg);
}

.u-block-events {
  cursor: not-allowed;
  opacity: .7;
  pointer-events: none;
}

.u-z-max {
  z-index: 10;
}