 // VARIABLES
// Desde aquí controlamos la fuente del proyecto
$font-family: 'Source-Sans';
$font-family2: 'AIG-Futura';
$font-size-main: 1rem;

// Prefijo para el proyecto
// Todos los componentes deben empezar por este prefijo seguido de un guíon + nameComponente.
$prefix: '.aig'; // Ejemplo: .icp o .cliente

// Mapa de colores.
// Lo usaremos para las variables de colores o dentro de cada componente.
$primary : (
  "primary1" : #001871,
  "primary2" : #B9C6D4,
  "primary3" : #337AB7
);

$secondary : (
  "secondary1" : #333333,
  "secondary2" : #4A4A49,
);

// State 1 hace referencia a primary1 | 2 a primary 2 | error a $semantic(error)
$state : (
  "disabled1" : #F2F6FC,
  "over1" : #0036FF,
  "selected1" : #1352DE,
  "disabled2" : #DFE5EC,
  "over2" : #617E9E,
  "selected2" : #5D738D,
  "disabled3" : #D7E7F4,
  "over3" : #347DBB,
);

$complementary : (
  "comp1": #EBF1FB,
  "comp2": #F6F6F6,
  "comp3": #D0D6D3,
  "comp4": #116399,
);

$semantic : (
  "warning-error" : #BF0019,
  "notice" : #EA9C0B,
  "info" : #007BFF,
  "success" : #62DA70, 
);

$grey : (
  "grey100" : #FCFCFC,
  "grey200" : #F2F2F2,
  "grey300" : #C4C4C4,
  "grey400" : #A8A8A8,
  "grey500" : #8C8C8C,
  "grey600" : #707070,
  "grey700" : #545454,
  "grey800" : #383838,
  "grey900" : #1C1C1C
);

$blackwhite : (
  "black" : #000000,
  "white" : #FFF,
  "transparent" : transparent,
  "inherit" :inherit
  );
  
// Variables colores del proyecto
// Se deben añadir o quitar las necesarias para el desarrollo

// Primary
$c-primary: map-get($primary, "primary1");
$c-primary-2: map-get($primary, "primary2");
$c-primary-3: map-get($primary, "primary3");
  
// Secondary
$c-secondary: map-get($secondary, "secondary1");
$c-secondary-2: map-get($secondary, "secondary2");
// $c-secondary-3: map-get($secondary, "secondary3");

// Grey
$c-grey-1: map-get($grey, "grey100");
$c-grey-2: map-get($grey, "grey200");
$c-grey-3: map-get($grey, "grey300");
$c-grey-4: map-get($grey, "grey400");
$c-grey-5: map-get($grey, "grey500");
$c-grey-6: map-get($grey, "grey600");
$c-grey-7: map-get($grey, "grey700");
$c-grey-8: map-get($grey, "grey800");
$c-grey-9: map-get($grey, "grey900"); 

// Black & White
$c-white: map-get($blackwhite, "white");
$c-black: map-get($blackwhite, "black");
$c-dark: map-get($blackwhite, "black");

// Semantic
$c-warning: map-get($semantic, "warning-error");
$c-notice: map-get($semantic, "notice");
$c-info: map-get($semantic, "info");
$c-success: map-get($semantic, "success");


// Complementary
$c-comp1: map-get($complementary, "comp1");
$c-comp2: map-get($complementary, "comp2");
$c-comp3: map-get($complementary, "comp3");
$c-comp4: map-get($complementary, "comp4");


// States
$c-disabled1: map-get($state, "disabled1");
$c-disabled2: map-get($state, "disabled2");
$c-disabled3: map-get($state, "disabled3");
$c-over1: map-get($state, "over1");
$c-over2: map-get($state, "over2");
$c-over3: map-get($state, "over3");
$c-selected1: map-get($state, "selected1");
$c-selected2: map-get($state, "selected2");
$c-selected3: map-get($state, "selected3");

$c-grey-light: $c-grey-4;
$c-light: $c-comp1;
$c-shadow: $c-grey-2;

// Mapa de sombras
$shadows: (
  "high": 0 0 20px rgba(215, 215, 215, 0.8),
  "medium": 0 0 16px rgba(215, 215, 215, 0.6),
  "low": 0 0 12px rgba(215, 215, 215, 0.4),
  "box": 0 0 8px rgba(172, 172, 172, 0.12),
  "sidebar": 0 0 10px rgba(57, 57, 57, 0.3),
);

$shadow-mid: map-get($map: $shadows, $key: "medium");


// Puntos de ruptura
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1400px;

$grid-breakpoints: (
  sm: $bp-sm,
  md: $bp-md,
  lg: $bp-lg,
  xl: $bp-xl,
);

//Mapa tamaños de fuentes solo para <p> o <span>
$fontsize-map: (
  "xxs": 10,
  "xs": 12,
  "sm": 14,
  "md": 16,
  "lg": 18,
  "xl": 20,
  "xxl": 22,
  "xxxl": 40,
  "28": 28,
  "32" : 32,
  "24" : 24,
  "inherit" : inherit
);
$f-12: map-get($fontsize-map, "xs");
$f-14: map-get($fontsize-map, "sm");
$f-16: map-get($fontsize-map, "md");
$f-18: map-get($fontsize-map, "md");
$f-20: map-get($fontsize-map, "xl");
$f-28: map-get($fontsize-map, "28");
$f-24: map-get($fontsize-map, "24");
$f-32: map-get($fontsize-map, "32");
$f-40: map-get($fontsize-map, "xxxl");

// Mapa tamaños cabeceras
$headings-map: (
  "h1" : 40,
  "h2" : 32,
  "h3" : 28,
  "h4" : 24
);

// Mapa grosor de textos
$fontweight-map: (
  "200": 200,
  "400": 400,
  "500": 500,
  "600": 600,
  "700": 700,
  "800": 800,
  "900": 900,
);

// Mapa para opacidades
$opacity-map: (
  0: 0,
  25: 25,
  50: 5,
  75: 75,
  100: 1,
);

// Mapa para alinear textos
$text-align-map: (
  "center" : center,
  "start" : start,
  "end" : end,
  "left" : left,
  "right" : right,
  "justify" : justify,
  "justify-all" : justify-all
);

// Mapa para cursores
$cursor-map: (
  "default" : default,
  "pointer" : pointer,
  "help" : help,
  "progress" : progress,
  "wait" : wait,
  "none" : none,
  "text" : text,
  "copy" : copy,
  "cell" : cell,
  "move" : move,
  "not-allowed" : not-allowed,
  "col-resize" : col-resize,
  "e-resize" : e-resize
);

// Mapa para posiciones
$position-map: (
  "absolute" : absolute,
  "relative" : relative,
  "fixed" : fixed,
  "sticky" : sticky,
  "inherit" : inherit,
  "static" : static,
  "initial" : initial
);

// Mapa arrangements
$arrangement-map: (
  "top" : top,
  "start" : left,
  "end" : right,
  "bottom" : bottom
);