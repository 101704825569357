.c-jumbotron {
  background-color: $c-disabled1;
  color: $c-primary-3;
  font-size: calcrem(12);
  padding: calcrem(36);
  width: 100%;

  &-primary {
    @extend .c-jumbotron;

    background-color: $c-comp1;
    color: $c-selected1;
  }

  &-error {
    @extend .c-jumbotron;

    background-color: $c-grey-2;
    color: $c-warning;
  }

  &-dashed {

    @extend .c-jumbotron;

    background-color: transparent;
    border: calcrem(1) dashed $c-primary;
    color: $c-primary;
  }


  &--pill {
    padding: calcrem(4) calcrem(8);
  }
}