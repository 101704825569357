
.c-panel {
  background-color: $c-disabled1;
  padding: calcrem(16) calcrem(32);
  width: fit-content;

  &__title {
    .icon {
      font-size: calcrem(39);
      margin: calcrem(16);
    }
  }
}
