
.c-nav {
  display: flex;
  width: fit-content;

  &__item {
    align-items: center;
    display: flex;
    cursor: pointer;
    text-transform: uppercase;
    padding: calcrem(8) calcrem(6);
    margin: 0 calcrem(16);

    &:hover {
      text-decoration: underline;
    }

    &--sub {
      @extend .c-nav__item;
      &:hover {
        text-decoration: none;
      }
    }
  }
}