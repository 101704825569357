.o-container {

    margin: 0 auto;
    max-width: 100%;

    @include sm-device {
        max-width: 540px;
    }

    @include md-device {
        max-width: 720px;
    }
    
    @include lg-device {
        max-width: 960px;
    }

    @include xl-device {
        max-width: 1140px;
    }

    @include xxl-device {
        max-width: 1320px;
    }

    @include min-width (1700px) {
        max-width: 66.66666667%;
    }



    &-lg {

        max-width: 100%;

        @include xxl-device {
            max-width: 1320px;
        }

        @include xl-device {
            max-width: 1140px;
        }

        @include lg-device {
            max-width: 960px;
        }
    }
}