.c-accordion {

  $self: &;

  @include flex-align (flex-start, flex-start);

  border-bottom: calcrem(2) solid $c-grey-3;
  flex-direction: column;
  overflow-y: hidden;
  padding: calcrem(22) 0;
  transition: height 1s ease-in;
  text-align: left;
  width: 100%;

  &:first-child {
    border-top: calcrem(2) solid $c-grey-3;
  }

  &__header {

    @include flex-align(center, space-between);

    width: inherit;
  }
  
  &__body {
    font-size: calcrem($f-16);
  }

  &__title {
    color: $c-primary;
    font-size: calcrem($f-28);

    @include xs-device {
      font-size: calcrem(18);
    }
    
    &--notexpanded {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      max-width: 90%;
      overflow: hidden;
      text-overflow: hidden;
    }
  }


  &__arrow {

    transition: transform .3s ease-in-out;

    &--rotated {
      transform: rotate(180deg);
    }
  }

  &--expanded {
    height: auto;
  }

}