body {
  font-family: $font-family;
  letter-spacing: 0.5px;

  table,
  div,
  p,
  span,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  button {
    font-family: $font-family;
    font-size: $font-size-main;
    line-height: 1.5;
    margin: 0;
  }

  h1, h2, h3, h4, h5 {
    line-height: 1.2;
    letter-spacing: 0;
  }

  strong {
    font-family: $font-family2;
    font-weight: 500;
  }

  a {
    background-color: transparent;
    &:hover {
      color: initial;
    }
  }
}