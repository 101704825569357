// Cursor options

@mixin cursor() {
  $cursor-list: map-values($cursor-map);

  @each $value in $cursor-list {
    .u-cursor-#{$value} {
      cursor: $value;
    }
  }
}

@include cursor();