/* BLOCK */
.e-radio {

  $self: &;

  @include flex-align(center, start);

  /*
    ELEMENTS
  */
  &__input {
    // height: calcrem(26);
    // width: calcrem(26);
    display: none;

    // &:checked {
    //   accent-color: $c-primary;
    // }
  }

  &__btn {
    border: calcrem(1) solid $c-primary;
    border-radius: 50%;
    height: calcrem(21);
    position: relative;
    width: calcrem(21);

    &::after {

      @include setWidthAndHeight(calcrem(11), calcrem(11));
      @include center-absolute;

      background-color: $c-primary;
      border-radius: 50%;
      content: "";
      display: none;
    } 

    &.active {
      &:after {
        display: block;
      }
    }

  }

  &__label {
    color: $c-black;
    margin-left: calcrem(5);
  }


}
