@font-face {
  src: url("../../assets/fonts/typo/source-sanspro-light.ttf") format("embedded-opentype"), url("../../assets/fonts/typo/source-sanspro-light.ttf") format("truetype");
  font-family: "Source-Sans";
  font-weight: 300;
  font-style: 300;
  font-strecht: condensed;
}
@font-face {
  src: url("../../assets/fonts/typo/source-sanspro-regular.ttf") format("embedded-opentype"), url("../../assets/fonts/typo/source-sanspro-regular.ttf") format("truetype");
  font-family: "Source-Sans";
  font-weight: 400;
  font-style: 300;
  font-strecht: condensed;
}
@font-face {
  src: url("../../assets/fonts/typo/source-sanspro-semibold.ttf") format("embedded-opentype"), url("../../assets/fonts/typo/source-sanspro-semibold.ttf") format("truetype");
  font-family: "Source-Sans";
  font-weight: 500;
  font-style: 300;
  font-strecht: condensed;
}
@font-face {
  src: url("../../assets/fonts/typo/source-sanspro-bold.ttf") format("embedded-opentype"), url("../../assets/fonts/typo/source-sanspro-bold.ttf") format("truetype");
  font-family: "Source-Sans";
  font-weight: 600;
  font-style: 300;
  font-strecht: condensed;
}
/* FONT FAMILY FUTURA */
@font-face {
  src: url("../../assets/fonts/typo/pt-aigfutura-book-webfont.ttf") format("embedded-opentype"), url("../../assets/fonts/typo/pt-aigfutura-book-webfont.ttf") format("truetype");
  font-family: "AIG-Futura";
  font-weight: normal;
  font-style: 300;
  font-strecht: condensed;
}
@font-face {
  src: url("../../assets/fonts/typo/pt-aigfutura-medium-webfont.ttf") format("embedded-opentype"), url("../../assets/fonts/typo/pt-aigfutura-medium-webfont.ttf") format("truetype");
  font-family: "AIG-Futura";
  font-weight: 500;
  font-style: 300;
  font-strecht: condensed;
}
@font-face {
  src: url("../../assets/fonts/typo/pt-aigfutura-bold-webfont.ttf") format("embedded-opentype"), url("../../assets/fonts/typo/pt-aigfutura-bold-webfont.ttf") format("truetype");
  font-family: "AIG-Futura";
  font-weight: 600;
  font-style: 300;
  font-strecht: condensed;
}
html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

select,
button {
  cursor: pointer;
}

input {
  background-image: none;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

body {
  font-family: "Source-Sans";
  letter-spacing: 0.5px;
}
body table,
body div,
body p,
body span,
body label,
body h1,
body h2,
body h3,
body h4,
body h5,
body button {
  font-family: "Source-Sans";
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}
body h1, body h2, body h3, body h4, body h5 {
  line-height: 1.2;
  letter-spacing: 0;
}
body strong {
  font-family: "AIG-Futura";
  font-weight: 500;
}
body a {
  background-color: transparent;
}
body a:hover {
  color: initial;
}

/* CHEATSHEET */
.u-color-primary1 {
  color: #001871 !important;
}
.u-color-primary2 {
  color: #B9C6D4 !important;
}
.u-color-primary3 {
  color: #337AB7 !important;
}
.u-color-secondary1 {
  color: #333333 !important;
}
.u-color-secondary2 {
  color: #4A4A49 !important;
}
.u-color-disabled1 {
  color: #F2F6FC !important;
}
.u-color-over1 {
  color: #0036FF !important;
}
.u-color-selected1 {
  color: #1352DE !important;
}
.u-color-disabled2 {
  color: #DFE5EC !important;
}
.u-color-over2 {
  color: #617E9E !important;
}
.u-color-selected2 {
  color: #5D738D !important;
}
.u-color-disabled3 {
  color: #D7E7F4 !important;
}
.u-color-over3 {
  color: #347DBB !important;
}
.u-color-comp1 {
  color: #EBF1FB !important;
}
.u-color-comp2 {
  color: #F6F6F6 !important;
}
.u-color-comp3 {
  color: #D0D6D3 !important;
}
.u-color-comp4 {
  color: #116399 !important;
}
.u-color-warning-error {
  color: #BF0019 !important;
}
.u-color-notice {
  color: #EA9C0B !important;
}
.u-color-info {
  color: #007BFF !important;
}
.u-color-success {
  color: #62DA70 !important;
}
.u-color-grey100 {
  color: #FCFCFC !important;
}
.u-color-grey200 {
  color: #F2F2F2 !important;
}
.u-color-grey300 {
  color: #C4C4C4 !important;
}
.u-color-grey400 {
  color: #A8A8A8 !important;
}
.u-color-grey500 {
  color: #8C8C8C !important;
}
.u-color-grey600 {
  color: #707070 !important;
}
.u-color-grey700 {
  color: #545454 !important;
}
.u-color-grey800 {
  color: #383838 !important;
}
.u-color-grey900 {
  color: #1C1C1C !important;
}
.u-color-black {
  color: #000000 !important;
}
.u-color-white {
  color: #FFF !important;
}
.u-color-transparent {
  color: transparent !important;
}
.u-color-inherit {
  color: inherit !important;
}

.u-bg-primary1 {
  background-color: #001871;
}
.u-bg-primary2 {
  background-color: #B9C6D4;
}
.u-bg-primary3 {
  background-color: #337AB7;
}
.u-bg-secondary1 {
  background-color: #333333;
}
.u-bg-secondary2 {
  background-color: #4A4A49;
}
.u-bg-disabled1 {
  background-color: #F2F6FC;
}
.u-bg-over1 {
  background-color: #0036FF;
}
.u-bg-selected1 {
  background-color: #1352DE;
}
.u-bg-disabled2 {
  background-color: #DFE5EC;
}
.u-bg-over2 {
  background-color: #617E9E;
}
.u-bg-selected2 {
  background-color: #5D738D;
}
.u-bg-disabled3 {
  background-color: #D7E7F4;
}
.u-bg-over3 {
  background-color: #347DBB;
}
.u-bg-comp1 {
  background-color: #EBF1FB;
}
.u-bg-comp2 {
  background-color: #F6F6F6;
}
.u-bg-comp3 {
  background-color: #D0D6D3;
}
.u-bg-comp4 {
  background-color: #116399;
}
.u-bg-warning-error {
  background-color: #BF0019;
}
.u-bg-notice {
  background-color: #EA9C0B;
}
.u-bg-info {
  background-color: #007BFF;
}
.u-bg-success {
  background-color: #62DA70;
}
.u-bg-grey100 {
  background-color: #FCFCFC;
}
.u-bg-grey200 {
  background-color: #F2F2F2;
}
.u-bg-grey300 {
  background-color: #C4C4C4;
}
.u-bg-grey400 {
  background-color: #A8A8A8;
}
.u-bg-grey500 {
  background-color: #8C8C8C;
}
.u-bg-grey600 {
  background-color: #707070;
}
.u-bg-grey700 {
  background-color: #545454;
}
.u-bg-grey800 {
  background-color: #383838;
}
.u-bg-grey900 {
  background-color: #1C1C1C;
}
.u-bg-black {
  background-color: #000000;
}
.u-bg-white {
  background-color: #FFF;
}
.u-bg-transparent {
  background-color: transparent;
}
.u-bg-inherit {
  background-color: inherit;
}

.u-shadow-high {
  box-shadow: 0 0 20px rgba(215, 215, 215, 0.8);
}
.u-shadow-medium {
  box-shadow: 0 0 16px rgba(215, 215, 215, 0.6);
}
.u-shadow-low {
  box-shadow: 0 0 12px rgba(215, 215, 215, 0.4);
}
.u-shadow-box {
  box-shadow: 0 0 8px rgba(172, 172, 172, 0.12);
}
.u-shadow-sidebar {
  box-shadow: 0 0 10px rgba(57, 57, 57, 0.3);
}

/* FONT SIZE */
.u-fs-10 {
  font-size: 0.625rem !important;
}

.u-fs-12 {
  font-size: 0.75rem !important;
}

.u-fs-14 {
  font-size: 0.875rem !important;
}

.u-fs-16 {
  font-size: 1rem !important;
}

.u-fs-18 {
  font-size: 1.125rem !important;
}

.u-fs-20 {
  font-size: 1.25rem !important;
}

.u-fs-22 {
  font-size: 1.375rem !important;
}

.u-fs-40 {
  font-size: 2.5rem !important;
}

.u-fs-28 {
  font-size: 1.75rem !important;
}

.u-fs-32 {
  font-size: 2rem !important;
}

.u-fs-24 {
  font-size: 1.5rem !important;
}

.u-fs-inherit {
  font-size: inherit/16rem !important;
}

/* FONT WEIGHT */
.u-fw-200 {
  font-weight: 200;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-fw-800 {
  font-weight: 800;
}

.u-fw-900 {
  font-weight: 900;
}

/* HEADINGS */
.u-h1 {
  font-size: 2.5rem;
}

.u-h2 {
  font-size: 2rem;
}

.u-h3 {
  font-size: 1.75rem;
}

.u-h4 {
  font-size: 1.5rem;
}

@media (max-width: 576px) {
  .u-fs-16\@xs {
    font-size: 1rem !important;
  }
}

.u-family-aig {
  font-family: "AIG-Futura";
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-help {
  cursor: help;
}

.u-cursor-progress {
  cursor: progress;
}

.u-cursor-wait {
  cursor: wait;
}

.u-cursor-none {
  cursor: none;
}

.u-cursor-text {
  cursor: text;
}

.u-cursor-copy {
  cursor: copy;
}

.u-cursor-cell {
  cursor: cell;
}

.u-cursor-move {
  cursor: move;
}

.u-cursor-not-allowed {
  cursor: not-allowed;
}

.u-cursor-col-resize {
  cursor: col-resize;
}

.u-cursor-e-resize {
  cursor: e-resize;
}

.u-text-center {
  text-align: center;
}
@media (min-width: 1400px) {
  .u-text-center\@xxl {
    text-align: center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-text-center\@xl {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-text-center\@lg {
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-text-center\@md {
    text-align: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-text-center\@sm {
    text-align: center;
  }
}
@media (max-width: 575px) {
  .u-text-center\@xs {
    text-align: center;
  }
}

.u-text-start {
  text-align: start;
}
@media (min-width: 1400px) {
  .u-text-start\@xxl {
    text-align: start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-text-start\@xl {
    text-align: start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-text-start\@lg {
    text-align: start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-text-start\@md {
    text-align: start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-text-start\@sm {
    text-align: start;
  }
}
@media (max-width: 575px) {
  .u-text-start\@xs {
    text-align: start;
  }
}

.u-text-end {
  text-align: end;
}
@media (min-width: 1400px) {
  .u-text-end\@xxl {
    text-align: end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-text-end\@xl {
    text-align: end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-text-end\@lg {
    text-align: end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-text-end\@md {
    text-align: end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-text-end\@sm {
    text-align: end;
  }
}
@media (max-width: 575px) {
  .u-text-end\@xs {
    text-align: end;
  }
}

.u-text-left {
  text-align: left;
}
@media (min-width: 1400px) {
  .u-text-left\@xxl {
    text-align: left;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-text-left\@xl {
    text-align: left;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-text-left\@lg {
    text-align: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-text-left\@md {
    text-align: left;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-text-left\@sm {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .u-text-left\@xs {
    text-align: left;
  }
}

.u-text-right {
  text-align: right;
}
@media (min-width: 1400px) {
  .u-text-right\@xxl {
    text-align: right;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-text-right\@xl {
    text-align: right;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-text-right\@lg {
    text-align: right;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-text-right\@md {
    text-align: right;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-text-right\@sm {
    text-align: right;
  }
}
@media (max-width: 575px) {
  .u-text-right\@xs {
    text-align: right;
  }
}

.u-text-justify {
  text-align: justify;
}
@media (min-width: 1400px) {
  .u-text-justify\@xxl {
    text-align: justify;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-text-justify\@xl {
    text-align: justify;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-text-justify\@lg {
    text-align: justify;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-text-justify\@md {
    text-align: justify;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-text-justify\@sm {
    text-align: justify;
  }
}
@media (max-width: 575px) {
  .u-text-justify\@xs {
    text-align: justify;
  }
}

.u-text-justify-all {
  text-align: justify-all;
}
@media (min-width: 1400px) {
  .u-text-justify-all\@xxl {
    text-align: justify-all;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-text-justify-all\@xl {
    text-align: justify-all;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-text-justify-all\@lg {
    text-align: justify-all;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-text-justify-all\@md {
    text-align: justify-all;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-text-justify-all\@sm {
    text-align: justify-all;
  }
}
@media (max-width: 575px) {
  .u-text-justify-all\@xs {
    text-align: justify-all;
  }
}

.u-size-per-0 {
  width: 0%;
}

.u-maxsize-per-0 {
  max-width: 0%;
}

.u-height-per-0 {
  height: 0%;
}

.u-maxheight-per-0 {
  max-height: 0%;
}

.u-minheight-per-0 {
  min-height: 0%;
}

.u-size-per-10 {
  width: 10%;
}

.u-maxsize-per-10 {
  max-width: 10%;
}

.u-height-per-10 {
  height: 10%;
}

.u-maxheight-per-10 {
  max-height: 10%;
}

.u-minheight-per-10 {
  min-height: 10%;
}

.u-size-per-20 {
  width: 20%;
}

.u-maxsize-per-20 {
  max-width: 20%;
}

.u-height-per-20 {
  height: 20%;
}

.u-maxheight-per-20 {
  max-height: 20%;
}

.u-minheight-per-20 {
  min-height: 20%;
}

.u-size-per-25 {
  width: 25%;
}

.u-maxsize-per-25 {
  max-width: 25%;
}

.u-height-per-25 {
  height: 25%;
}

.u-maxheight-per-25 {
  max-height: 25%;
}

.u-minheight-per-25 {
  min-height: 25%;
}

.u-size-per-30 {
  width: 30%;
}

.u-maxsize-per-30 {
  max-width: 30%;
}

.u-height-per-30 {
  height: 30%;
}

.u-maxheight-per-30 {
  max-height: 30%;
}

.u-minheight-per-30 {
  min-height: 30%;
}

.u-size-per-35 {
  width: 35%;
}

.u-maxsize-per-35 {
  max-width: 35%;
}

.u-height-per-35 {
  height: 35%;
}

.u-maxheight-per-35 {
  max-height: 35%;
}

.u-minheight-per-35 {
  min-height: 35%;
}

.u-size-per-40 {
  width: 40%;
}

.u-maxsize-per-40 {
  max-width: 40%;
}

.u-height-per-40 {
  height: 40%;
}

.u-maxheight-per-40 {
  max-height: 40%;
}

.u-minheight-per-40 {
  min-height: 40%;
}

.u-size-per-45 {
  width: 45%;
}

.u-maxsize-per-45 {
  max-width: 45%;
}

.u-height-per-45 {
  height: 45%;
}

.u-maxheight-per-45 {
  max-height: 45%;
}

.u-minheight-per-45 {
  min-height: 45%;
}

.u-size-per-50 {
  width: 50%;
}

.u-maxsize-per-50 {
  max-width: 50%;
}

.u-height-per-50 {
  height: 50%;
}

.u-maxheight-per-50 {
  max-height: 50%;
}

.u-minheight-per-50 {
  min-height: 50%;
}

.u-size-per-60 {
  width: 60%;
}

.u-maxsize-per-60 {
  max-width: 60%;
}

.u-height-per-60 {
  height: 60%;
}

.u-maxheight-per-60 {
  max-height: 60%;
}

.u-minheight-per-60 {
  min-height: 60%;
}

.u-size-per-65 {
  width: 65%;
}

.u-maxsize-per-65 {
  max-width: 65%;
}

.u-height-per-65 {
  height: 65%;
}

.u-maxheight-per-65 {
  max-height: 65%;
}

.u-minheight-per-65 {
  min-height: 65%;
}

.u-size-per-70 {
  width: 70%;
}

.u-maxsize-per-70 {
  max-width: 70%;
}

.u-height-per-70 {
  height: 70%;
}

.u-maxheight-per-70 {
  max-height: 70%;
}

.u-minheight-per-70 {
  min-height: 70%;
}

.u-size-per-75 {
  width: 75%;
}

.u-maxsize-per-75 {
  max-width: 75%;
}

.u-height-per-75 {
  height: 75%;
}

.u-maxheight-per-75 {
  max-height: 75%;
}

.u-minheight-per-75 {
  min-height: 75%;
}

.u-size-per-80 {
  width: 80%;
}

.u-maxsize-per-80 {
  max-width: 80%;
}

.u-height-per-80 {
  height: 80%;
}

.u-maxheight-per-80 {
  max-height: 80%;
}

.u-minheight-per-80 {
  min-height: 80%;
}

.u-size-per-85 {
  width: 85%;
}

.u-maxsize-per-85 {
  max-width: 85%;
}

.u-height-per-85 {
  height: 85%;
}

.u-maxheight-per-85 {
  max-height: 85%;
}

.u-minheight-per-85 {
  min-height: 85%;
}

.u-size-per-90 {
  width: 90%;
}

.u-maxsize-per-90 {
  max-width: 90%;
}

.u-height-per-90 {
  height: 90%;
}

.u-maxheight-per-90 {
  max-height: 90%;
}

.u-minheight-per-90 {
  min-height: 90%;
}

.u-size-per-100 {
  width: 100%;
}

.u-maxsize-per-100 {
  max-width: 100%;
}

.u-height-per-100 {
  height: 100%;
}

.u-maxheight-per-100 {
  max-height: 100%;
}

.u-minheight-per-100 {
  min-height: 100%;
}

.u-size-vw-0 {
  width: 0vw;
}

.u-maxsize-vw-0 {
  max-width: 0vw;
}

.u-height-vw-0 {
  height: 0vw;
}

.u-maxheight-vw-0 {
  max-height: 0vw;
}

.u-minheight-vw-0 {
  min-height: 0vw;
}

.u-size-vw-10 {
  width: 10vw;
}

.u-maxsize-vw-10 {
  max-width: 10vw;
}

.u-height-vw-10 {
  height: 10vw;
}

.u-maxheight-vw-10 {
  max-height: 10vw;
}

.u-minheight-vw-10 {
  min-height: 10vw;
}

.u-size-vw-20 {
  width: 20vw;
}

.u-maxsize-vw-20 {
  max-width: 20vw;
}

.u-height-vw-20 {
  height: 20vw;
}

.u-maxheight-vw-20 {
  max-height: 20vw;
}

.u-minheight-vw-20 {
  min-height: 20vw;
}

.u-size-vw-25 {
  width: 25vw;
}

.u-maxsize-vw-25 {
  max-width: 25vw;
}

.u-height-vw-25 {
  height: 25vw;
}

.u-maxheight-vw-25 {
  max-height: 25vw;
}

.u-minheight-vw-25 {
  min-height: 25vw;
}

.u-size-vw-30 {
  width: 30vw;
}

.u-maxsize-vw-30 {
  max-width: 30vw;
}

.u-height-vw-30 {
  height: 30vw;
}

.u-maxheight-vw-30 {
  max-height: 30vw;
}

.u-minheight-vw-30 {
  min-height: 30vw;
}

.u-size-vw-35 {
  width: 35vw;
}

.u-maxsize-vw-35 {
  max-width: 35vw;
}

.u-height-vw-35 {
  height: 35vw;
}

.u-maxheight-vw-35 {
  max-height: 35vw;
}

.u-minheight-vw-35 {
  min-height: 35vw;
}

.u-size-vw-40 {
  width: 40vw;
}

.u-maxsize-vw-40 {
  max-width: 40vw;
}

.u-height-vw-40 {
  height: 40vw;
}

.u-maxheight-vw-40 {
  max-height: 40vw;
}

.u-minheight-vw-40 {
  min-height: 40vw;
}

.u-size-vw-45 {
  width: 45vw;
}

.u-maxsize-vw-45 {
  max-width: 45vw;
}

.u-height-vw-45 {
  height: 45vw;
}

.u-maxheight-vw-45 {
  max-height: 45vw;
}

.u-minheight-vw-45 {
  min-height: 45vw;
}

.u-size-vw-50 {
  width: 50vw;
}

.u-maxsize-vw-50 {
  max-width: 50vw;
}

.u-height-vw-50 {
  height: 50vw;
}

.u-maxheight-vw-50 {
  max-height: 50vw;
}

.u-minheight-vw-50 {
  min-height: 50vw;
}

.u-size-vw-60 {
  width: 60vw;
}

.u-maxsize-vw-60 {
  max-width: 60vw;
}

.u-height-vw-60 {
  height: 60vw;
}

.u-maxheight-vw-60 {
  max-height: 60vw;
}

.u-minheight-vw-60 {
  min-height: 60vw;
}

.u-size-vw-65 {
  width: 65vw;
}

.u-maxsize-vw-65 {
  max-width: 65vw;
}

.u-height-vw-65 {
  height: 65vw;
}

.u-maxheight-vw-65 {
  max-height: 65vw;
}

.u-minheight-vw-65 {
  min-height: 65vw;
}

.u-size-vw-70 {
  width: 70vw;
}

.u-maxsize-vw-70 {
  max-width: 70vw;
}

.u-height-vw-70 {
  height: 70vw;
}

.u-maxheight-vw-70 {
  max-height: 70vw;
}

.u-minheight-vw-70 {
  min-height: 70vw;
}

.u-size-vw-75 {
  width: 75vw;
}

.u-maxsize-vw-75 {
  max-width: 75vw;
}

.u-height-vw-75 {
  height: 75vw;
}

.u-maxheight-vw-75 {
  max-height: 75vw;
}

.u-minheight-vw-75 {
  min-height: 75vw;
}

.u-size-vw-80 {
  width: 80vw;
}

.u-maxsize-vw-80 {
  max-width: 80vw;
}

.u-height-vw-80 {
  height: 80vw;
}

.u-maxheight-vw-80 {
  max-height: 80vw;
}

.u-minheight-vw-80 {
  min-height: 80vw;
}

.u-size-vw-85 {
  width: 85vw;
}

.u-maxsize-vw-85 {
  max-width: 85vw;
}

.u-height-vw-85 {
  height: 85vw;
}

.u-maxheight-vw-85 {
  max-height: 85vw;
}

.u-minheight-vw-85 {
  min-height: 85vw;
}

.u-size-vw-90 {
  width: 90vw;
}

.u-maxsize-vw-90 {
  max-width: 90vw;
}

.u-height-vw-90 {
  height: 90vw;
}

.u-maxheight-vw-90 {
  max-height: 90vw;
}

.u-minheight-vw-90 {
  min-height: 90vw;
}

.u-size-vw-100 {
  width: 100vw;
}

.u-maxsize-vw-100 {
  max-width: 100vw;
}

.u-height-vw-100 {
  height: 100vw;
}

.u-maxheight-vw-100 {
  max-height: 100vw;
}

.u-minheight-vw-100 {
  min-height: 100vw;
}

.u-size-vh-0 {
  width: 0vh;
}

.u-maxsize-vh-0 {
  max-width: 0vh;
}

.u-height-vh-0 {
  height: 0vh;
}

.u-maxheight-vh-0 {
  max-height: 0vh;
}

.u-minheight-vh-0 {
  min-height: 0vh;
}

.u-size-vh-10 {
  width: 10vh;
}

.u-maxsize-vh-10 {
  max-width: 10vh;
}

.u-height-vh-10 {
  height: 10vh;
}

.u-maxheight-vh-10 {
  max-height: 10vh;
}

.u-minheight-vh-10 {
  min-height: 10vh;
}

.u-size-vh-20 {
  width: 20vh;
}

.u-maxsize-vh-20 {
  max-width: 20vh;
}

.u-height-vh-20 {
  height: 20vh;
}

.u-maxheight-vh-20 {
  max-height: 20vh;
}

.u-minheight-vh-20 {
  min-height: 20vh;
}

.u-size-vh-25 {
  width: 25vh;
}

.u-maxsize-vh-25 {
  max-width: 25vh;
}

.u-height-vh-25 {
  height: 25vh;
}

.u-maxheight-vh-25 {
  max-height: 25vh;
}

.u-minheight-vh-25 {
  min-height: 25vh;
}

.u-size-vh-30 {
  width: 30vh;
}

.u-maxsize-vh-30 {
  max-width: 30vh;
}

.u-height-vh-30 {
  height: 30vh;
}

.u-maxheight-vh-30 {
  max-height: 30vh;
}

.u-minheight-vh-30 {
  min-height: 30vh;
}

.u-size-vh-35 {
  width: 35vh;
}

.u-maxsize-vh-35 {
  max-width: 35vh;
}

.u-height-vh-35 {
  height: 35vh;
}

.u-maxheight-vh-35 {
  max-height: 35vh;
}

.u-minheight-vh-35 {
  min-height: 35vh;
}

.u-size-vh-40 {
  width: 40vh;
}

.u-maxsize-vh-40 {
  max-width: 40vh;
}

.u-height-vh-40 {
  height: 40vh;
}

.u-maxheight-vh-40 {
  max-height: 40vh;
}

.u-minheight-vh-40 {
  min-height: 40vh;
}

.u-size-vh-45 {
  width: 45vh;
}

.u-maxsize-vh-45 {
  max-width: 45vh;
}

.u-height-vh-45 {
  height: 45vh;
}

.u-maxheight-vh-45 {
  max-height: 45vh;
}

.u-minheight-vh-45 {
  min-height: 45vh;
}

.u-size-vh-50 {
  width: 50vh;
}

.u-maxsize-vh-50 {
  max-width: 50vh;
}

.u-height-vh-50 {
  height: 50vh;
}

.u-maxheight-vh-50 {
  max-height: 50vh;
}

.u-minheight-vh-50 {
  min-height: 50vh;
}

.u-size-vh-60 {
  width: 60vh;
}

.u-maxsize-vh-60 {
  max-width: 60vh;
}

.u-height-vh-60 {
  height: 60vh;
}

.u-maxheight-vh-60 {
  max-height: 60vh;
}

.u-minheight-vh-60 {
  min-height: 60vh;
}

.u-size-vh-65 {
  width: 65vh;
}

.u-maxsize-vh-65 {
  max-width: 65vh;
}

.u-height-vh-65 {
  height: 65vh;
}

.u-maxheight-vh-65 {
  max-height: 65vh;
}

.u-minheight-vh-65 {
  min-height: 65vh;
}

.u-size-vh-70 {
  width: 70vh;
}

.u-maxsize-vh-70 {
  max-width: 70vh;
}

.u-height-vh-70 {
  height: 70vh;
}

.u-maxheight-vh-70 {
  max-height: 70vh;
}

.u-minheight-vh-70 {
  min-height: 70vh;
}

.u-size-vh-75 {
  width: 75vh;
}

.u-maxsize-vh-75 {
  max-width: 75vh;
}

.u-height-vh-75 {
  height: 75vh;
}

.u-maxheight-vh-75 {
  max-height: 75vh;
}

.u-minheight-vh-75 {
  min-height: 75vh;
}

.u-size-vh-80 {
  width: 80vh;
}

.u-maxsize-vh-80 {
  max-width: 80vh;
}

.u-height-vh-80 {
  height: 80vh;
}

.u-maxheight-vh-80 {
  max-height: 80vh;
}

.u-minheight-vh-80 {
  min-height: 80vh;
}

.u-size-vh-85 {
  width: 85vh;
}

.u-maxsize-vh-85 {
  max-width: 85vh;
}

.u-height-vh-85 {
  height: 85vh;
}

.u-maxheight-vh-85 {
  max-height: 85vh;
}

.u-minheight-vh-85 {
  min-height: 85vh;
}

.u-size-vh-90 {
  width: 90vh;
}

.u-maxsize-vh-90 {
  max-width: 90vh;
}

.u-height-vh-90 {
  height: 90vh;
}

.u-maxheight-vh-90 {
  max-height: 90vh;
}

.u-minheight-vh-90 {
  min-height: 90vh;
}

.u-size-vh-100 {
  width: 100vh;
}

.u-maxsize-vh-100 {
  max-width: 100vh;
}

.u-height-vh-100 {
  height: 100vh;
}

.u-maxheight-vh-100 {
  max-height: 100vh;
}

.u-minheight-vh-100 {
  min-height: 100vh;
}

.u-size-vmin-0 {
  width: 0vmin;
}

.u-maxsize-vmin-0 {
  max-width: 0vmin;
}

.u-height-vmin-0 {
  height: 0vmin;
}

.u-maxheight-vmin-0 {
  max-height: 0vmin;
}

.u-minheight-vmin-0 {
  min-height: 0vmin;
}

.u-size-vmin-10 {
  width: 10vmin;
}

.u-maxsize-vmin-10 {
  max-width: 10vmin;
}

.u-height-vmin-10 {
  height: 10vmin;
}

.u-maxheight-vmin-10 {
  max-height: 10vmin;
}

.u-minheight-vmin-10 {
  min-height: 10vmin;
}

.u-size-vmin-20 {
  width: 20vmin;
}

.u-maxsize-vmin-20 {
  max-width: 20vmin;
}

.u-height-vmin-20 {
  height: 20vmin;
}

.u-maxheight-vmin-20 {
  max-height: 20vmin;
}

.u-minheight-vmin-20 {
  min-height: 20vmin;
}

.u-size-vmin-25 {
  width: 25vmin;
}

.u-maxsize-vmin-25 {
  max-width: 25vmin;
}

.u-height-vmin-25 {
  height: 25vmin;
}

.u-maxheight-vmin-25 {
  max-height: 25vmin;
}

.u-minheight-vmin-25 {
  min-height: 25vmin;
}

.u-size-vmin-30 {
  width: 30vmin;
}

.u-maxsize-vmin-30 {
  max-width: 30vmin;
}

.u-height-vmin-30 {
  height: 30vmin;
}

.u-maxheight-vmin-30 {
  max-height: 30vmin;
}

.u-minheight-vmin-30 {
  min-height: 30vmin;
}

.u-size-vmin-35 {
  width: 35vmin;
}

.u-maxsize-vmin-35 {
  max-width: 35vmin;
}

.u-height-vmin-35 {
  height: 35vmin;
}

.u-maxheight-vmin-35 {
  max-height: 35vmin;
}

.u-minheight-vmin-35 {
  min-height: 35vmin;
}

.u-size-vmin-40 {
  width: 40vmin;
}

.u-maxsize-vmin-40 {
  max-width: 40vmin;
}

.u-height-vmin-40 {
  height: 40vmin;
}

.u-maxheight-vmin-40 {
  max-height: 40vmin;
}

.u-minheight-vmin-40 {
  min-height: 40vmin;
}

.u-size-vmin-45 {
  width: 45vmin;
}

.u-maxsize-vmin-45 {
  max-width: 45vmin;
}

.u-height-vmin-45 {
  height: 45vmin;
}

.u-maxheight-vmin-45 {
  max-height: 45vmin;
}

.u-minheight-vmin-45 {
  min-height: 45vmin;
}

.u-size-vmin-50 {
  width: 50vmin;
}

.u-maxsize-vmin-50 {
  max-width: 50vmin;
}

.u-height-vmin-50 {
  height: 50vmin;
}

.u-maxheight-vmin-50 {
  max-height: 50vmin;
}

.u-minheight-vmin-50 {
  min-height: 50vmin;
}

.u-size-vmin-60 {
  width: 60vmin;
}

.u-maxsize-vmin-60 {
  max-width: 60vmin;
}

.u-height-vmin-60 {
  height: 60vmin;
}

.u-maxheight-vmin-60 {
  max-height: 60vmin;
}

.u-minheight-vmin-60 {
  min-height: 60vmin;
}

.u-size-vmin-65 {
  width: 65vmin;
}

.u-maxsize-vmin-65 {
  max-width: 65vmin;
}

.u-height-vmin-65 {
  height: 65vmin;
}

.u-maxheight-vmin-65 {
  max-height: 65vmin;
}

.u-minheight-vmin-65 {
  min-height: 65vmin;
}

.u-size-vmin-70 {
  width: 70vmin;
}

.u-maxsize-vmin-70 {
  max-width: 70vmin;
}

.u-height-vmin-70 {
  height: 70vmin;
}

.u-maxheight-vmin-70 {
  max-height: 70vmin;
}

.u-minheight-vmin-70 {
  min-height: 70vmin;
}

.u-size-vmin-75 {
  width: 75vmin;
}

.u-maxsize-vmin-75 {
  max-width: 75vmin;
}

.u-height-vmin-75 {
  height: 75vmin;
}

.u-maxheight-vmin-75 {
  max-height: 75vmin;
}

.u-minheight-vmin-75 {
  min-height: 75vmin;
}

.u-size-vmin-80 {
  width: 80vmin;
}

.u-maxsize-vmin-80 {
  max-width: 80vmin;
}

.u-height-vmin-80 {
  height: 80vmin;
}

.u-maxheight-vmin-80 {
  max-height: 80vmin;
}

.u-minheight-vmin-80 {
  min-height: 80vmin;
}

.u-size-vmin-85 {
  width: 85vmin;
}

.u-maxsize-vmin-85 {
  max-width: 85vmin;
}

.u-height-vmin-85 {
  height: 85vmin;
}

.u-maxheight-vmin-85 {
  max-height: 85vmin;
}

.u-minheight-vmin-85 {
  min-height: 85vmin;
}

.u-size-vmin-90 {
  width: 90vmin;
}

.u-maxsize-vmin-90 {
  max-width: 90vmin;
}

.u-height-vmin-90 {
  height: 90vmin;
}

.u-maxheight-vmin-90 {
  max-height: 90vmin;
}

.u-minheight-vmin-90 {
  min-height: 90vmin;
}

.u-size-vmin-100 {
  width: 100vmin;
}

.u-maxsize-vmin-100 {
  max-width: 100vmin;
}

.u-height-vmin-100 {
  height: 100vmin;
}

.u-maxheight-vmin-100 {
  max-height: 100vmin;
}

.u-minheight-vmin-100 {
  min-height: 100vmin;
}

.u-size-vmax-0 {
  width: 0vmax;
}

.u-maxsize-vmax-0 {
  max-width: 0vmax;
}

.u-height-vmax-0 {
  height: 0vmax;
}

.u-maxheight-vmax-0 {
  max-height: 0vmax;
}

.u-minheight-vmax-0 {
  min-height: 0vmax;
}

.u-size-vmax-10 {
  width: 10vmax;
}

.u-maxsize-vmax-10 {
  max-width: 10vmax;
}

.u-height-vmax-10 {
  height: 10vmax;
}

.u-maxheight-vmax-10 {
  max-height: 10vmax;
}

.u-minheight-vmax-10 {
  min-height: 10vmax;
}

.u-size-vmax-20 {
  width: 20vmax;
}

.u-maxsize-vmax-20 {
  max-width: 20vmax;
}

.u-height-vmax-20 {
  height: 20vmax;
}

.u-maxheight-vmax-20 {
  max-height: 20vmax;
}

.u-minheight-vmax-20 {
  min-height: 20vmax;
}

.u-size-vmax-25 {
  width: 25vmax;
}

.u-maxsize-vmax-25 {
  max-width: 25vmax;
}

.u-height-vmax-25 {
  height: 25vmax;
}

.u-maxheight-vmax-25 {
  max-height: 25vmax;
}

.u-minheight-vmax-25 {
  min-height: 25vmax;
}

.u-size-vmax-30 {
  width: 30vmax;
}

.u-maxsize-vmax-30 {
  max-width: 30vmax;
}

.u-height-vmax-30 {
  height: 30vmax;
}

.u-maxheight-vmax-30 {
  max-height: 30vmax;
}

.u-minheight-vmax-30 {
  min-height: 30vmax;
}

.u-size-vmax-35 {
  width: 35vmax;
}

.u-maxsize-vmax-35 {
  max-width: 35vmax;
}

.u-height-vmax-35 {
  height: 35vmax;
}

.u-maxheight-vmax-35 {
  max-height: 35vmax;
}

.u-minheight-vmax-35 {
  min-height: 35vmax;
}

.u-size-vmax-40 {
  width: 40vmax;
}

.u-maxsize-vmax-40 {
  max-width: 40vmax;
}

.u-height-vmax-40 {
  height: 40vmax;
}

.u-maxheight-vmax-40 {
  max-height: 40vmax;
}

.u-minheight-vmax-40 {
  min-height: 40vmax;
}

.u-size-vmax-45 {
  width: 45vmax;
}

.u-maxsize-vmax-45 {
  max-width: 45vmax;
}

.u-height-vmax-45 {
  height: 45vmax;
}

.u-maxheight-vmax-45 {
  max-height: 45vmax;
}

.u-minheight-vmax-45 {
  min-height: 45vmax;
}

.u-size-vmax-50 {
  width: 50vmax;
}

.u-maxsize-vmax-50 {
  max-width: 50vmax;
}

.u-height-vmax-50 {
  height: 50vmax;
}

.u-maxheight-vmax-50 {
  max-height: 50vmax;
}

.u-minheight-vmax-50 {
  min-height: 50vmax;
}

.u-size-vmax-60 {
  width: 60vmax;
}

.u-maxsize-vmax-60 {
  max-width: 60vmax;
}

.u-height-vmax-60 {
  height: 60vmax;
}

.u-maxheight-vmax-60 {
  max-height: 60vmax;
}

.u-minheight-vmax-60 {
  min-height: 60vmax;
}

.u-size-vmax-65 {
  width: 65vmax;
}

.u-maxsize-vmax-65 {
  max-width: 65vmax;
}

.u-height-vmax-65 {
  height: 65vmax;
}

.u-maxheight-vmax-65 {
  max-height: 65vmax;
}

.u-minheight-vmax-65 {
  min-height: 65vmax;
}

.u-size-vmax-70 {
  width: 70vmax;
}

.u-maxsize-vmax-70 {
  max-width: 70vmax;
}

.u-height-vmax-70 {
  height: 70vmax;
}

.u-maxheight-vmax-70 {
  max-height: 70vmax;
}

.u-minheight-vmax-70 {
  min-height: 70vmax;
}

.u-size-vmax-75 {
  width: 75vmax;
}

.u-maxsize-vmax-75 {
  max-width: 75vmax;
}

.u-height-vmax-75 {
  height: 75vmax;
}

.u-maxheight-vmax-75 {
  max-height: 75vmax;
}

.u-minheight-vmax-75 {
  min-height: 75vmax;
}

.u-size-vmax-80 {
  width: 80vmax;
}

.u-maxsize-vmax-80 {
  max-width: 80vmax;
}

.u-height-vmax-80 {
  height: 80vmax;
}

.u-maxheight-vmax-80 {
  max-height: 80vmax;
}

.u-minheight-vmax-80 {
  min-height: 80vmax;
}

.u-size-vmax-85 {
  width: 85vmax;
}

.u-maxsize-vmax-85 {
  max-width: 85vmax;
}

.u-height-vmax-85 {
  height: 85vmax;
}

.u-maxheight-vmax-85 {
  max-height: 85vmax;
}

.u-minheight-vmax-85 {
  min-height: 85vmax;
}

.u-size-vmax-90 {
  width: 90vmax;
}

.u-maxsize-vmax-90 {
  max-width: 90vmax;
}

.u-height-vmax-90 {
  height: 90vmax;
}

.u-maxheight-vmax-90 {
  max-height: 90vmax;
}

.u-minheight-vmax-90 {
  min-height: 90vmax;
}

.u-size-vmax-100 {
  width: 100vmax;
}

.u-maxsize-vmax-100 {
  max-width: 100vmax;
}

.u-height-vmax-100 {
  height: 100vmax;
}

.u-maxheight-vmax-100 {
  max-height: 100vmax;
}

.u-minheight-vmax-100 {
  min-height: 100vmax;
}

.u-size-max {
  width: max-content;
}

.u-maxsize-max {
  max-width: max-content;
}

.u-height-max {
  height: max-content;
}

.u-maxheight-max {
  max-height: max-content;
}

.u-size-min {
  width: min-content;
}

.u-maxsize-min {
  max-width: min-content;
}

.u-height-min {
  height: min-content;
}

.u-maxheight-min {
  max-height: min-content;
}

.u-size-content {
  width: fit-content;
}

.u-maxsize-content {
  max-width: fit-content;
}

.u-height-content {
  height: fit-content;
}

.u-maxheight-content {
  max-height: fit-content;
}

.u-size-available {
  width: -webkit-fill-available;
}

.u-maxsize-available {
  max-width: -webkit-fill-available;
}

.u-height-available {
  height: -webkit-fill-available;
}

.u-maxheight-available {
  max-height: -webkit-fill-available;
}

.u-size-auto {
  width: auto;
}

.u-maxsize-auto {
  max-width: auto;
}

.u-height-auto {
  height: auto;
}

.u-maxheight-auto {
  max-height: auto;
}

.u-size-unset {
  width: unset;
}

.u-maxsize-unset {
  max-width: unset;
}

.u-height-unset {
  height: unset;
}

.u-maxheight-unset {
  max-height: unset;
}

.u-size-initial {
  width: initial;
}

.u-maxsize-initial {
  max-width: initial;
}

.u-height-initial {
  height: initial;
}

.u-maxheight-initial {
  max-height: initial;
}

.u-size-inherit {
  width: inherit;
}

.u-maxsize-inherit {
  max-width: inherit;
}

.u-height-inherit {
  height: inherit;
}

.u-maxheight-inherit {
  max-height: inherit;
}

.u-height-1 {
  height: 1rem;
}

.u-height-2 {
  height: 2rem;
}

.u-height-3 {
  height: 3rem;
}

.u-height-4 {
  height: 4rem;
}

.u-ds-block {
  display: block;
}
@media (min-width: 1400px) {
  .u-ds-block\@xxl {
    display: block;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-block\@xl {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-block\@lg {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-block\@md {
    display: block;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-block\@sm {
    display: block;
  }
}
@media (max-width: 575px) {
  .u-ds-block\@xs {
    display: block;
  }
}

.u-ds-none {
  display: none;
}
@media (min-width: 1400px) {
  .u-ds-none\@xxl {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-none\@xl {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-none\@lg {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-none\@md {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-none\@sm {
    display: none;
  }
}
@media (max-width: 575px) {
  .u-ds-none\@xs {
    display: none;
  }
}

.u-ds-flex {
  display: flex;
}
@media (min-width: 1400px) {
  .u-ds-flex\@xxl {
    display: flex;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-flex\@xl {
    display: flex;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-flex\@lg {
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-flex\@md {
    display: flex;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-flex\@sm {
    display: flex;
  }
}
@media (max-width: 575px) {
  .u-ds-flex\@xs {
    display: flex;
  }
}

.u-ds-inline {
  display: inline;
}
@media (min-width: 1400px) {
  .u-ds-inline\@xxl {
    display: inline;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-inline\@xl {
    display: inline;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-inline\@lg {
    display: inline;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-inline\@md {
    display: inline;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-inline\@sm {
    display: inline;
  }
}
@media (max-width: 575px) {
  .u-ds-inline\@xs {
    display: inline;
  }
}

.u-ds-inline-block {
  display: inline-block;
}
@media (min-width: 1400px) {
  .u-ds-inline-block\@xxl {
    display: inline-block;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-inline-block\@xl {
    display: inline-block;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-inline-block\@lg {
    display: inline-block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-inline-block\@md {
    display: inline-block;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-inline-block\@sm {
    display: inline-block;
  }
}
@media (max-width: 575px) {
  .u-ds-inline-block\@xs {
    display: inline-block;
  }
}

.u-ds-inline-flex {
  display: inline-flex;
}
@media (min-width: 1400px) {
  .u-ds-inline-flex\@xxl {
    display: inline-flex;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-inline-flex\@xl {
    display: inline-flex;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-inline-flex\@lg {
    display: inline-flex;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-inline-flex\@md {
    display: inline-flex;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-inline-flex\@sm {
    display: inline-flex;
  }
}
@media (max-width: 575px) {
  .u-ds-inline-flex\@xs {
    display: inline-flex;
  }
}

.u-ds-grid {
  display: grid;
}
@media (min-width: 1400px) {
  .u-ds-grid\@xxl {
    display: grid;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-grid\@xl {
    display: grid;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-grid\@lg {
    display: grid;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-grid\@md {
    display: grid;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-grid\@sm {
    display: grid;
  }
}
@media (max-width: 575px) {
  .u-ds-grid\@xs {
    display: grid;
  }
}

.u-ds-table {
  display: table;
}
@media (min-width: 1400px) {
  .u-ds-table\@xxl {
    display: table;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-table\@xl {
    display: table;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-table\@lg {
    display: table;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-table\@md {
    display: table;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-table\@sm {
    display: table;
  }
}
@media (max-width: 575px) {
  .u-ds-table\@xs {
    display: table;
  }
}

.u-ds-table-cell {
  display: table-cell;
}
@media (min-width: 1400px) {
  .u-ds-table-cell\@xxl {
    display: table-cell;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ds-table-cell\@xl {
    display: table-cell;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ds-table-cell\@lg {
    display: table-cell;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ds-table-cell\@md {
    display: table-cell;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ds-table-cell\@sm {
    display: table-cell;
  }
}
@media (max-width: 575px) {
  .u-ds-table-cell\@xs {
    display: table-cell;
  }
}

.u-justify-start {
  justify-content: start;
}
@media (min-width: 1400px) {
  .u-justify-start\@xxl {
    justify-content: start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-justify-start\@xl {
    justify-content: start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-justify-start\@lg {
    justify-content: start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-justify-start\@md {
    justify-content: start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-justify-start\@sm {
    justify-content: start;
  }
}
@media (max-width: 575px) {
  .u-justify-start\@xs {
    justify-content: start;
  }
}

.u-justify-end {
  justify-content: end;
}
@media (min-width: 1400px) {
  .u-justify-end\@xxl {
    justify-content: end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-justify-end\@xl {
    justify-content: end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-justify-end\@lg {
    justify-content: end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-justify-end\@md {
    justify-content: end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-justify-end\@sm {
    justify-content: end;
  }
}
@media (max-width: 575px) {
  .u-justify-end\@xs {
    justify-content: end;
  }
}

.u-justify-between {
  justify-content: space-between;
}
@media (min-width: 1400px) {
  .u-justify-between\@xxl {
    justify-content: space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-justify-between\@xl {
    justify-content: space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-justify-between\@lg {
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-justify-between\@md {
    justify-content: space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-justify-between\@sm {
    justify-content: space-between;
  }
}
@media (max-width: 575px) {
  .u-justify-between\@xs {
    justify-content: space-between;
  }
}

.u-justify-around {
  justify-content: space-around;
}
@media (min-width: 1400px) {
  .u-justify-around\@xxl {
    justify-content: space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-justify-around\@xl {
    justify-content: space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-justify-around\@lg {
    justify-content: space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-justify-around\@md {
    justify-content: space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-justify-around\@sm {
    justify-content: space-around;
  }
}
@media (max-width: 575px) {
  .u-justify-around\@xs {
    justify-content: space-around;
  }
}

.u-justify-center {
  justify-content: center;
}
@media (min-width: 1400px) {
  .u-justify-center\@xxl {
    justify-content: center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-justify-center\@xl {
    justify-content: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-justify-center\@lg {
    justify-content: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-justify-center\@md {
    justify-content: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-justify-center\@sm {
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .u-justify-center\@xs {
    justify-content: center;
  }
}

.u-align-start {
  align-items: start;
}
@media (min-width: 1400px) {
  .u-align-start\@xxl {
    align-items: start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-align-start\@xl {
    align-items: start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-align-start\@lg {
    align-items: start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-align-start\@md {
    align-items: start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-align-start\@sm {
    align-items: start;
  }
}
@media (max-width: 575px) {
  .u-align-start\@xs {
    align-items: start;
  }
}

.u-align-end {
  align-items: end;
}
@media (min-width: 1400px) {
  .u-align-end\@xxl {
    align-items: end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-align-end\@xl {
    align-items: end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-align-end\@lg {
    align-items: end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-align-end\@md {
    align-items: end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-align-end\@sm {
    align-items: end;
  }
}
@media (max-width: 575px) {
  .u-align-end\@xs {
    align-items: end;
  }
}

.u-align-between {
  align-items: space-between;
}
@media (min-width: 1400px) {
  .u-align-between\@xxl {
    align-items: space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-align-between\@xl {
    align-items: space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-align-between\@lg {
    align-items: space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-align-between\@md {
    align-items: space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-align-between\@sm {
    align-items: space-between;
  }
}
@media (max-width: 575px) {
  .u-align-between\@xs {
    align-items: space-between;
  }
}

.u-align-around {
  align-items: space-around;
}
@media (min-width: 1400px) {
  .u-align-around\@xxl {
    align-items: space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-align-around\@xl {
    align-items: space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-align-around\@lg {
    align-items: space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-align-around\@md {
    align-items: space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-align-around\@sm {
    align-items: space-around;
  }
}
@media (max-width: 575px) {
  .u-align-around\@xs {
    align-items: space-around;
  }
}

.u-align-center {
  align-items: center;
}
@media (min-width: 1400px) {
  .u-align-center\@xxl {
    align-items: center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-align-center\@xl {
    align-items: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-align-center\@lg {
    align-items: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-align-center\@md {
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-align-center\@sm {
    align-items: center;
  }
}
@media (max-width: 575px) {
  .u-align-center\@xs {
    align-items: center;
  }
}

.u-place-start {
  place-content: start;
}
@media (min-width: 1400px) {
  .u-place-start\@xxl {
    place-content: start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-start\@xl {
    place-content: start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-start\@lg {
    place-content: start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-start\@md {
    place-content: start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-start\@sm {
    place-content: start;
  }
}
@media (max-width: 575px) {
  .u-place-start\@xs {
    place-content: start;
  }
}

.u-place-end {
  place-content: end;
}
@media (min-width: 1400px) {
  .u-place-end\@xxl {
    place-content: end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-end\@xl {
    place-content: end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-end\@lg {
    place-content: end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-end\@md {
    place-content: end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-end\@sm {
    place-content: end;
  }
}
@media (max-width: 575px) {
  .u-place-end\@xs {
    place-content: end;
  }
}

.u-place-between {
  place-content: space-between;
}
@media (min-width: 1400px) {
  .u-place-between\@xxl {
    place-content: space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-between\@xl {
    place-content: space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-between\@lg {
    place-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-between\@md {
    place-content: space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-between\@sm {
    place-content: space-between;
  }
}
@media (max-width: 575px) {
  .u-place-between\@xs {
    place-content: space-between;
  }
}

.u-place-around {
  place-content: space-around;
}
@media (min-width: 1400px) {
  .u-place-around\@xxl {
    place-content: space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-around\@xl {
    place-content: space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-around\@lg {
    place-content: space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-around\@md {
    place-content: space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-around\@sm {
    place-content: space-around;
  }
}
@media (max-width: 575px) {
  .u-place-around\@xs {
    place-content: space-around;
  }
}

.u-place-center {
  place-content: center;
}
@media (min-width: 1400px) {
  .u-place-center\@xxl {
    place-content: center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-center\@xl {
    place-content: center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-center\@lg {
    place-content: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-center\@md {
    place-content: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-center\@sm {
    place-content: center;
  }
}
@media (max-width: 575px) {
  .u-place-center\@xs {
    place-content: center;
  }
}

.u-place-start-start {
  place-content: start start;
}
@media (min-width: 1400px) {
  .u-place-start-start\@xxl {
    place-content: start start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-start-start\@xl {
    place-content: start start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-start-start\@lg {
    place-content: start start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-start-start\@md {
    place-content: start start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-start-start\@sm {
    place-content: start start;
  }
}
@media (max-width: 575px) {
  .u-place-start-start\@xs {
    place-content: start start;
  }
}

.u-place-start-end {
  place-content: start end;
}
@media (min-width: 1400px) {
  .u-place-start-end\@xxl {
    place-content: start end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-start-end\@xl {
    place-content: start end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-start-end\@lg {
    place-content: start end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-start-end\@md {
    place-content: start end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-start-end\@sm {
    place-content: start end;
  }
}
@media (max-width: 575px) {
  .u-place-start-end\@xs {
    place-content: start end;
  }
}

.u-place-start-space-between {
  place-content: start space-between;
}
@media (min-width: 1400px) {
  .u-place-start-space-between\@xxl {
    place-content: start space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-start-space-between\@xl {
    place-content: start space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-start-space-between\@lg {
    place-content: start space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-start-space-between\@md {
    place-content: start space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-start-space-between\@sm {
    place-content: start space-between;
  }
}
@media (max-width: 575px) {
  .u-place-start-space-between\@xs {
    place-content: start space-between;
  }
}

.u-place-start-space-around {
  place-content: start space-around;
}
@media (min-width: 1400px) {
  .u-place-start-space-around\@xxl {
    place-content: start space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-start-space-around\@xl {
    place-content: start space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-start-space-around\@lg {
    place-content: start space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-start-space-around\@md {
    place-content: start space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-start-space-around\@sm {
    place-content: start space-around;
  }
}
@media (max-width: 575px) {
  .u-place-start-space-around\@xs {
    place-content: start space-around;
  }
}

.u-place-start-center {
  place-content: start center;
}
@media (min-width: 1400px) {
  .u-place-start-center\@xxl {
    place-content: start center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-start-center\@xl {
    place-content: start center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-start-center\@lg {
    place-content: start center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-start-center\@md {
    place-content: start center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-start-center\@sm {
    place-content: start center;
  }
}
@media (max-width: 575px) {
  .u-place-start-center\@xs {
    place-content: start center;
  }
}

.u-place-end-start {
  place-content: end start;
}
@media (min-width: 1400px) {
  .u-place-end-start\@xxl {
    place-content: end start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-end-start\@xl {
    place-content: end start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-end-start\@lg {
    place-content: end start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-end-start\@md {
    place-content: end start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-end-start\@sm {
    place-content: end start;
  }
}
@media (max-width: 575px) {
  .u-place-end-start\@xs {
    place-content: end start;
  }
}

.u-place-end-end {
  place-content: end end;
}
@media (min-width: 1400px) {
  .u-place-end-end\@xxl {
    place-content: end end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-end-end\@xl {
    place-content: end end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-end-end\@lg {
    place-content: end end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-end-end\@md {
    place-content: end end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-end-end\@sm {
    place-content: end end;
  }
}
@media (max-width: 575px) {
  .u-place-end-end\@xs {
    place-content: end end;
  }
}

.u-place-end-space-between {
  place-content: end space-between;
}
@media (min-width: 1400px) {
  .u-place-end-space-between\@xxl {
    place-content: end space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-end-space-between\@xl {
    place-content: end space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-end-space-between\@lg {
    place-content: end space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-end-space-between\@md {
    place-content: end space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-end-space-between\@sm {
    place-content: end space-between;
  }
}
@media (max-width: 575px) {
  .u-place-end-space-between\@xs {
    place-content: end space-between;
  }
}

.u-place-end-space-around {
  place-content: end space-around;
}
@media (min-width: 1400px) {
  .u-place-end-space-around\@xxl {
    place-content: end space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-end-space-around\@xl {
    place-content: end space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-end-space-around\@lg {
    place-content: end space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-end-space-around\@md {
    place-content: end space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-end-space-around\@sm {
    place-content: end space-around;
  }
}
@media (max-width: 575px) {
  .u-place-end-space-around\@xs {
    place-content: end space-around;
  }
}

.u-place-end-center {
  place-content: end center;
}
@media (min-width: 1400px) {
  .u-place-end-center\@xxl {
    place-content: end center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-end-center\@xl {
    place-content: end center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-end-center\@lg {
    place-content: end center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-end-center\@md {
    place-content: end center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-end-center\@sm {
    place-content: end center;
  }
}
@media (max-width: 575px) {
  .u-place-end-center\@xs {
    place-content: end center;
  }
}

.u-place-space-between-start {
  place-content: space-between start;
}
@media (min-width: 1400px) {
  .u-place-space-between-start\@xxl {
    place-content: space-between start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-between-start\@xl {
    place-content: space-between start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-between-start\@lg {
    place-content: space-between start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-between-start\@md {
    place-content: space-between start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-between-start\@sm {
    place-content: space-between start;
  }
}
@media (max-width: 575px) {
  .u-place-space-between-start\@xs {
    place-content: space-between start;
  }
}

.u-place-space-between-end {
  place-content: space-between end;
}
@media (min-width: 1400px) {
  .u-place-space-between-end\@xxl {
    place-content: space-between end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-between-end\@xl {
    place-content: space-between end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-between-end\@lg {
    place-content: space-between end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-between-end\@md {
    place-content: space-between end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-between-end\@sm {
    place-content: space-between end;
  }
}
@media (max-width: 575px) {
  .u-place-space-between-end\@xs {
    place-content: space-between end;
  }
}

.u-place-space-between-space-between {
  place-content: space-between space-between;
}
@media (min-width: 1400px) {
  .u-place-space-between-space-between\@xxl {
    place-content: space-between space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-between-space-between\@xl {
    place-content: space-between space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-between-space-between\@lg {
    place-content: space-between space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-between-space-between\@md {
    place-content: space-between space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-between-space-between\@sm {
    place-content: space-between space-between;
  }
}
@media (max-width: 575px) {
  .u-place-space-between-space-between\@xs {
    place-content: space-between space-between;
  }
}

.u-place-space-between-space-around {
  place-content: space-between space-around;
}
@media (min-width: 1400px) {
  .u-place-space-between-space-around\@xxl {
    place-content: space-between space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-between-space-around\@xl {
    place-content: space-between space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-between-space-around\@lg {
    place-content: space-between space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-between-space-around\@md {
    place-content: space-between space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-between-space-around\@sm {
    place-content: space-between space-around;
  }
}
@media (max-width: 575px) {
  .u-place-space-between-space-around\@xs {
    place-content: space-between space-around;
  }
}

.u-place-space-between-center {
  place-content: space-between center;
}
@media (min-width: 1400px) {
  .u-place-space-between-center\@xxl {
    place-content: space-between center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-between-center\@xl {
    place-content: space-between center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-between-center\@lg {
    place-content: space-between center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-between-center\@md {
    place-content: space-between center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-between-center\@sm {
    place-content: space-between center;
  }
}
@media (max-width: 575px) {
  .u-place-space-between-center\@xs {
    place-content: space-between center;
  }
}

.u-place-space-around-start {
  place-content: space-around start;
}
@media (min-width: 1400px) {
  .u-place-space-around-start\@xxl {
    place-content: space-around start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-around-start\@xl {
    place-content: space-around start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-around-start\@lg {
    place-content: space-around start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-around-start\@md {
    place-content: space-around start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-around-start\@sm {
    place-content: space-around start;
  }
}
@media (max-width: 575px) {
  .u-place-space-around-start\@xs {
    place-content: space-around start;
  }
}

.u-place-space-around-end {
  place-content: space-around end;
}
@media (min-width: 1400px) {
  .u-place-space-around-end\@xxl {
    place-content: space-around end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-around-end\@xl {
    place-content: space-around end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-around-end\@lg {
    place-content: space-around end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-around-end\@md {
    place-content: space-around end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-around-end\@sm {
    place-content: space-around end;
  }
}
@media (max-width: 575px) {
  .u-place-space-around-end\@xs {
    place-content: space-around end;
  }
}

.u-place-space-around-space-between {
  place-content: space-around space-between;
}
@media (min-width: 1400px) {
  .u-place-space-around-space-between\@xxl {
    place-content: space-around space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-around-space-between\@xl {
    place-content: space-around space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-around-space-between\@lg {
    place-content: space-around space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-around-space-between\@md {
    place-content: space-around space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-around-space-between\@sm {
    place-content: space-around space-between;
  }
}
@media (max-width: 575px) {
  .u-place-space-around-space-between\@xs {
    place-content: space-around space-between;
  }
}

.u-place-space-around-space-around {
  place-content: space-around space-around;
}
@media (min-width: 1400px) {
  .u-place-space-around-space-around\@xxl {
    place-content: space-around space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-around-space-around\@xl {
    place-content: space-around space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-around-space-around\@lg {
    place-content: space-around space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-around-space-around\@md {
    place-content: space-around space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-around-space-around\@sm {
    place-content: space-around space-around;
  }
}
@media (max-width: 575px) {
  .u-place-space-around-space-around\@xs {
    place-content: space-around space-around;
  }
}

.u-place-space-around-center {
  place-content: space-around center;
}
@media (min-width: 1400px) {
  .u-place-space-around-center\@xxl {
    place-content: space-around center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-space-around-center\@xl {
    place-content: space-around center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-space-around-center\@lg {
    place-content: space-around center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-space-around-center\@md {
    place-content: space-around center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-space-around-center\@sm {
    place-content: space-around center;
  }
}
@media (max-width: 575px) {
  .u-place-space-around-center\@xs {
    place-content: space-around center;
  }
}

.u-place-center-start {
  place-content: center start;
}
@media (min-width: 1400px) {
  .u-place-center-start\@xxl {
    place-content: center start;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-center-start\@xl {
    place-content: center start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-center-start\@lg {
    place-content: center start;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-center-start\@md {
    place-content: center start;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-center-start\@sm {
    place-content: center start;
  }
}
@media (max-width: 575px) {
  .u-place-center-start\@xs {
    place-content: center start;
  }
}

.u-place-center-end {
  place-content: center end;
}
@media (min-width: 1400px) {
  .u-place-center-end\@xxl {
    place-content: center end;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-center-end\@xl {
    place-content: center end;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-center-end\@lg {
    place-content: center end;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-center-end\@md {
    place-content: center end;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-center-end\@sm {
    place-content: center end;
  }
}
@media (max-width: 575px) {
  .u-place-center-end\@xs {
    place-content: center end;
  }
}

.u-place-center-space-between {
  place-content: center space-between;
}
@media (min-width: 1400px) {
  .u-place-center-space-between\@xxl {
    place-content: center space-between;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-center-space-between\@xl {
    place-content: center space-between;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-center-space-between\@lg {
    place-content: center space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-center-space-between\@md {
    place-content: center space-between;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-center-space-between\@sm {
    place-content: center space-between;
  }
}
@media (max-width: 575px) {
  .u-place-center-space-between\@xs {
    place-content: center space-between;
  }
}

.u-place-center-space-around {
  place-content: center space-around;
}
@media (min-width: 1400px) {
  .u-place-center-space-around\@xxl {
    place-content: center space-around;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-center-space-around\@xl {
    place-content: center space-around;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-center-space-around\@lg {
    place-content: center space-around;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-center-space-around\@md {
    place-content: center space-around;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-center-space-around\@sm {
    place-content: center space-around;
  }
}
@media (max-width: 575px) {
  .u-place-center-space-around\@xs {
    place-content: center space-around;
  }
}

.u-place-center-center {
  place-content: center center;
}
@media (min-width: 1400px) {
  .u-place-center-center\@xxl {
    place-content: center center;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-place-center-center\@xl {
    place-content: center center;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-place-center-center\@lg {
    place-content: center center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-place-center-center\@md {
    place-content: center center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-place-center-center\@sm {
    place-content: center center;
  }
}
@media (max-width: 575px) {
  .u-place-center-center\@xs {
    place-content: center center;
  }
}

.u-dir-col {
  flex-direction: column;
}
@media (min-width: 1400px) {
  .u-dir-col\@xxl {
    flex-direction: column;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-dir-col\@xl {
    flex-direction: column;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-dir-col\@lg {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-dir-col\@md {
    flex-direction: column;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-dir-col\@sm {
    flex-direction: column;
  }
}
@media (max-width: 575px) {
  .u-dir-col\@xs {
    flex-direction: column;
  }
}

.u-dir-colrev {
  flex-direction: column-reverse;
}
@media (min-width: 1400px) {
  .u-dir-colrev\@xxl {
    flex-direction: column-reverse;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-dir-colrev\@xl {
    flex-direction: column-reverse;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-dir-colrev\@lg {
    flex-direction: column-reverse;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-dir-colrev\@md {
    flex-direction: column-reverse;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-dir-colrev\@sm {
    flex-direction: column-reverse;
  }
}
@media (max-width: 575px) {
  .u-dir-colrev\@xs {
    flex-direction: column-reverse;
  }
}

.u-dir-rev {
  flex-direction: revert;
}
@media (min-width: 1400px) {
  .u-dir-rev\@xxl {
    flex-direction: revert;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-dir-rev\@xl {
    flex-direction: revert;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-dir-rev\@lg {
    flex-direction: revert;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-dir-rev\@md {
    flex-direction: revert;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-dir-rev\@sm {
    flex-direction: revert;
  }
}
@media (max-width: 575px) {
  .u-dir-rev\@xs {
    flex-direction: revert;
  }
}

.u-dir-row {
  flex-direction: row;
}
@media (min-width: 1400px) {
  .u-dir-row\@xxl {
    flex-direction: row;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-dir-row\@xl {
    flex-direction: row;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-dir-row\@lg {
    flex-direction: row;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-dir-row\@md {
    flex-direction: row;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-dir-row\@sm {
    flex-direction: row;
  }
}
@media (max-width: 575px) {
  .u-dir-row\@xs {
    flex-direction: row;
  }
}

.u-dir-rowrev {
  flex-direction: row-reverse;
}
@media (min-width: 1400px) {
  .u-dir-rowrev\@xxl {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-dir-rowrev\@xl {
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-dir-rowrev\@lg {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-dir-rowrev\@md {
    flex-direction: row-reverse;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-dir-rowrev\@sm {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .u-dir-rowrev\@xs {
    flex-direction: row-reverse;
  }
}

.u-color-primary1 {
  color: #001871 !important;
}
.u-color-primary2 {
  color: #B9C6D4 !important;
}
.u-color-primary3 {
  color: #337AB7 !important;
}
.u-color-secondary1 {
  color: #333333 !important;
}
.u-color-secondary2 {
  color: #4A4A49 !important;
}
.u-color-disabled1 {
  color: #F2F6FC !important;
}
.u-color-over1 {
  color: #0036FF !important;
}
.u-color-selected1 {
  color: #1352DE !important;
}
.u-color-disabled2 {
  color: #DFE5EC !important;
}
.u-color-over2 {
  color: #617E9E !important;
}
.u-color-selected2 {
  color: #5D738D !important;
}
.u-color-disabled3 {
  color: #D7E7F4 !important;
}
.u-color-over3 {
  color: #347DBB !important;
}
.u-color-comp1 {
  color: #EBF1FB !important;
}
.u-color-comp2 {
  color: #F6F6F6 !important;
}
.u-color-comp3 {
  color: #D0D6D3 !important;
}
.u-color-comp4 {
  color: #116399 !important;
}
.u-color-warning-error {
  color: #BF0019 !important;
}
.u-color-notice {
  color: #EA9C0B !important;
}
.u-color-info {
  color: #007BFF !important;
}
.u-color-success {
  color: #62DA70 !important;
}
.u-color-grey100 {
  color: #FCFCFC !important;
}
.u-color-grey200 {
  color: #F2F2F2 !important;
}
.u-color-grey300 {
  color: #C4C4C4 !important;
}
.u-color-grey400 {
  color: #A8A8A8 !important;
}
.u-color-grey500 {
  color: #8C8C8C !important;
}
.u-color-grey600 {
  color: #707070 !important;
}
.u-color-grey700 {
  color: #545454 !important;
}
.u-color-grey800 {
  color: #383838 !important;
}
.u-color-grey900 {
  color: #1C1C1C !important;
}
.u-color-black {
  color: #000000 !important;
}
.u-color-white {
  color: #FFF !important;
}
.u-color-transparent {
  color: transparent !important;
}
.u-color-inherit {
  color: inherit !important;
}

.u-bg-primary1 {
  background-color: #001871;
}
.u-bg-primary2 {
  background-color: #B9C6D4;
}
.u-bg-primary3 {
  background-color: #337AB7;
}
.u-bg-secondary1 {
  background-color: #333333;
}
.u-bg-secondary2 {
  background-color: #4A4A49;
}
.u-bg-disabled1 {
  background-color: #F2F6FC;
}
.u-bg-over1 {
  background-color: #0036FF;
}
.u-bg-selected1 {
  background-color: #1352DE;
}
.u-bg-disabled2 {
  background-color: #DFE5EC;
}
.u-bg-over2 {
  background-color: #617E9E;
}
.u-bg-selected2 {
  background-color: #5D738D;
}
.u-bg-disabled3 {
  background-color: #D7E7F4;
}
.u-bg-over3 {
  background-color: #347DBB;
}
.u-bg-comp1 {
  background-color: #EBF1FB;
}
.u-bg-comp2 {
  background-color: #F6F6F6;
}
.u-bg-comp3 {
  background-color: #D0D6D3;
}
.u-bg-comp4 {
  background-color: #116399;
}
.u-bg-warning-error {
  background-color: #BF0019;
}
.u-bg-notice {
  background-color: #EA9C0B;
}
.u-bg-info {
  background-color: #007BFF;
}
.u-bg-success {
  background-color: #62DA70;
}
.u-bg-grey100 {
  background-color: #FCFCFC;
}
.u-bg-grey200 {
  background-color: #F2F2F2;
}
.u-bg-grey300 {
  background-color: #C4C4C4;
}
.u-bg-grey400 {
  background-color: #A8A8A8;
}
.u-bg-grey500 {
  background-color: #8C8C8C;
}
.u-bg-grey600 {
  background-color: #707070;
}
.u-bg-grey700 {
  background-color: #545454;
}
.u-bg-grey800 {
  background-color: #383838;
}
.u-bg-grey900 {
  background-color: #1C1C1C;
}
.u-bg-black {
  background-color: #000000;
}
.u-bg-white {
  background-color: #FFF;
}
.u-bg-transparent {
  background-color: transparent;
}
.u-bg-inherit {
  background-color: inherit;
}

.u-shadow-high {
  box-shadow: 0 0 20px rgba(215, 215, 215, 0.8);
}
.u-shadow-medium {
  box-shadow: 0 0 16px rgba(215, 215, 215, 0.6);
}
.u-shadow-low {
  box-shadow: 0 0 12px rgba(215, 215, 215, 0.4);
}
.u-shadow-box {
  box-shadow: 0 0 8px rgba(172, 172, 172, 0.12);
}
.u-shadow-sidebar {
  box-shadow: 0 0 10px rgba(57, 57, 57, 0.3);
}

.u-pos-absolute {
  position: absolute;
}

.u-pos-relative {
  position: relative;
}

.u-pos-fixed {
  position: fixed;
}

.u-pos-sticky {
  position: sticky;
}

.u-pos-inherit {
  position: inherit;
}

.u-pos-static {
  position: static;
}

.u-pos-initial {
  position: initial;
}

.u-top-0 {
  top: 0;
}

.u-top-50 {
  top: 50%;
}

.u-top-100 {
  top: 100%;
}

.u-start-0 {
  left: 0;
}

.u-start-50 {
  left: 50%;
}

.u-start-100 {
  left: 100%;
}

.u-end-0 {
  right: 0;
}

.u-end-50 {
  right: 50%;
}

.u-end-100 {
  right: 100%;
}

.u-bottom-0 {
  bottom: 0;
}

.u-bottom-50 {
  bottom: 50%;
}

.u-bottom-100 {
  bottom: 100%;
}

/* MARGIN */
.u-m-0 {
  margin: 0rem;
}
@media (min-width: 1400px) {
  .u-m-0\@xxl {
    margin: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-0\@xl {
    margin: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-0\@lg {
    margin: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-0\@md {
    margin: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-0\@sm {
    margin: 0rem;
  }
}
@media (max-width: 575px) {
  .u-m-0\@xs {
    margin: 0rem;
  }
}

.u-m-1 {
  margin: 0.25rem;
}
@media (min-width: 1400px) {
  .u-m-1\@xxl {
    margin: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-1\@xl {
    margin: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-1\@lg {
    margin: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-1\@md {
    margin: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-1\@sm {
    margin: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-m-1\@xs {
    margin: 0.25rem;
  }
}

.u-m-2 {
  margin: 0.5rem;
}
@media (min-width: 1400px) {
  .u-m-2\@xxl {
    margin: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-2\@xl {
    margin: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-2\@lg {
    margin: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-2\@md {
    margin: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-2\@sm {
    margin: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-m-2\@xs {
    margin: 0.5rem;
  }
}

.u-m-3 {
  margin: 1rem;
}
@media (min-width: 1400px) {
  .u-m-3\@xxl {
    margin: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-3\@xl {
    margin: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-3\@lg {
    margin: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-3\@md {
    margin: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-3\@sm {
    margin: 1rem;
  }
}
@media (max-width: 575px) {
  .u-m-3\@xs {
    margin: 1rem;
  }
}

.u-m-4 {
  margin: 1.25rem;
}
@media (min-width: 1400px) {
  .u-m-4\@xxl {
    margin: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-4\@xl {
    margin: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-4\@lg {
    margin: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-4\@md {
    margin: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-4\@sm {
    margin: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-m-4\@xs {
    margin: 1.25rem;
  }
}

.u-m-5 {
  margin: 1.5rem;
}
@media (min-width: 1400px) {
  .u-m-5\@xxl {
    margin: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-5\@xl {
    margin: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-5\@lg {
    margin: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-5\@md {
    margin: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-5\@sm {
    margin: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-m-5\@xs {
    margin: 1.5rem;
  }
}

.u-m-6 {
  margin: 1.75rem;
}
@media (min-width: 1400px) {
  .u-m-6\@xxl {
    margin: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-6\@xl {
    margin: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-6\@lg {
    margin: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-6\@md {
    margin: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-6\@sm {
    margin: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-m-6\@xs {
    margin: 1.75rem;
  }
}

.u-m-7 {
  margin: 2rem;
}
@media (min-width: 1400px) {
  .u-m-7\@xxl {
    margin: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-7\@xl {
    margin: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-7\@lg {
    margin: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-7\@md {
    margin: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-7\@sm {
    margin: 2rem;
  }
}
@media (max-width: 575px) {
  .u-m-7\@xs {
    margin: 2rem;
  }
}

.u-m-8 {
  margin: 2.5rem;
}
@media (min-width: 1400px) {
  .u-m-8\@xxl {
    margin: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-8\@xl {
    margin: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-8\@lg {
    margin: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-8\@md {
    margin: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-8\@sm {
    margin: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-m-8\@xs {
    margin: 2.5rem;
  }
}

.u-m-9 {
  margin: 3rem;
}
@media (min-width: 1400px) {
  .u-m-9\@xxl {
    margin: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-9\@xl {
    margin: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-9\@lg {
    margin: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-9\@md {
    margin: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-9\@sm {
    margin: 3rem;
  }
}
@media (max-width: 575px) {
  .u-m-9\@xs {
    margin: 3rem;
  }
}

.u-m-def {
  margin: 0.875rem;
}
@media (min-width: 1400px) {
  .u-m-def\@xxl {
    margin: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-m-def\@xl {
    margin: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-m-def\@lg {
    margin: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-m-def\@md {
    margin: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-m-def\@sm {
    margin: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-m-def\@xs {
    margin: 0.875rem;
  }
}

.u-mt-0 {
  margin-top: 0rem;
}
@media (min-width: 1400px) {
  .u-mt-0\@xxl {
    margin-top: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-0\@xl {
    margin-top: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-0\@lg {
    margin-top: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-0\@md {
    margin-top: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-0\@sm {
    margin-top: 0rem;
  }
}
@media (max-width: 575px) {
  .u-mt-0\@xs {
    margin-top: 0rem;
  }
}

.u-mt-1 {
  margin-top: 0.25rem;
}
@media (min-width: 1400px) {
  .u-mt-1\@xxl {
    margin-top: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-1\@xl {
    margin-top: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-1\@lg {
    margin-top: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-1\@md {
    margin-top: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-1\@sm {
    margin-top: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-mt-1\@xs {
    margin-top: 0.25rem;
  }
}

.u-mt-2 {
  margin-top: 0.5rem;
}
@media (min-width: 1400px) {
  .u-mt-2\@xxl {
    margin-top: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-2\@xl {
    margin-top: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-2\@lg {
    margin-top: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-2\@md {
    margin-top: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-2\@sm {
    margin-top: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-mt-2\@xs {
    margin-top: 0.5rem;
  }
}

.u-mt-3 {
  margin-top: 1rem;
}
@media (min-width: 1400px) {
  .u-mt-3\@xxl {
    margin-top: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-3\@xl {
    margin-top: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-3\@lg {
    margin-top: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-3\@md {
    margin-top: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-3\@sm {
    margin-top: 1rem;
  }
}
@media (max-width: 575px) {
  .u-mt-3\@xs {
    margin-top: 1rem;
  }
}

.u-mt-4 {
  margin-top: 1.25rem;
}
@media (min-width: 1400px) {
  .u-mt-4\@xxl {
    margin-top: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-4\@xl {
    margin-top: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-4\@lg {
    margin-top: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-4\@md {
    margin-top: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-4\@sm {
    margin-top: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-mt-4\@xs {
    margin-top: 1.25rem;
  }
}

.u-mt-5 {
  margin-top: 1.5rem;
}
@media (min-width: 1400px) {
  .u-mt-5\@xxl {
    margin-top: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-5\@xl {
    margin-top: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-5\@lg {
    margin-top: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-5\@md {
    margin-top: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-5\@sm {
    margin-top: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-mt-5\@xs {
    margin-top: 1.5rem;
  }
}

.u-mt-6 {
  margin-top: 1.75rem;
}
@media (min-width: 1400px) {
  .u-mt-6\@xxl {
    margin-top: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-6\@xl {
    margin-top: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-6\@lg {
    margin-top: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-6\@md {
    margin-top: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-6\@sm {
    margin-top: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-mt-6\@xs {
    margin-top: 1.75rem;
  }
}

.u-mt-7 {
  margin-top: 2rem;
}
@media (min-width: 1400px) {
  .u-mt-7\@xxl {
    margin-top: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-7\@xl {
    margin-top: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-7\@lg {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-7\@md {
    margin-top: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-7\@sm {
    margin-top: 2rem;
  }
}
@media (max-width: 575px) {
  .u-mt-7\@xs {
    margin-top: 2rem;
  }
}

.u-mt-8 {
  margin-top: 2.5rem;
}
@media (min-width: 1400px) {
  .u-mt-8\@xxl {
    margin-top: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-8\@xl {
    margin-top: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-8\@lg {
    margin-top: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-8\@md {
    margin-top: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-8\@sm {
    margin-top: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-mt-8\@xs {
    margin-top: 2.5rem;
  }
}

.u-mt-9 {
  margin-top: 3rem;
}
@media (min-width: 1400px) {
  .u-mt-9\@xxl {
    margin-top: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-9\@xl {
    margin-top: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-9\@lg {
    margin-top: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-9\@md {
    margin-top: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-9\@sm {
    margin-top: 3rem;
  }
}
@media (max-width: 575px) {
  .u-mt-9\@xs {
    margin-top: 3rem;
  }
}

.u-mt-def {
  margin-top: 0.875rem;
}
@media (min-width: 1400px) {
  .u-mt-def\@xxl {
    margin-top: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mt-def\@xl {
    margin-top: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mt-def\@lg {
    margin-top: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mt-def\@md {
    margin-top: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mt-def\@sm {
    margin-top: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-mt-def\@xs {
    margin-top: 0.875rem;
  }
}

.u-mb-0 {
  margin-bottom: 0rem;
}
@media (min-width: 1400px) {
  .u-mb-0\@xxl {
    margin-bottom: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-0\@xl {
    margin-bottom: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-0\@lg {
    margin-bottom: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-0\@md {
    margin-bottom: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-0\@sm {
    margin-bottom: 0rem;
  }
}
@media (max-width: 575px) {
  .u-mb-0\@xs {
    margin-bottom: 0rem;
  }
}

.u-mb-1 {
  margin-bottom: 0.25rem;
}
@media (min-width: 1400px) {
  .u-mb-1\@xxl {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-1\@xl {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-1\@lg {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-1\@md {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-1\@sm {
    margin-bottom: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-mb-1\@xs {
    margin-bottom: 0.25rem;
  }
}

.u-mb-2 {
  margin-bottom: 0.5rem;
}
@media (min-width: 1400px) {
  .u-mb-2\@xxl {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-2\@xl {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-2\@lg {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-2\@md {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-2\@sm {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-mb-2\@xs {
    margin-bottom: 0.5rem;
  }
}

.u-mb-3 {
  margin-bottom: 1rem;
}
@media (min-width: 1400px) {
  .u-mb-3\@xxl {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-3\@xl {
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-3\@lg {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-3\@md {
    margin-bottom: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-3\@sm {
    margin-bottom: 1rem;
  }
}
@media (max-width: 575px) {
  .u-mb-3\@xs {
    margin-bottom: 1rem;
  }
}

.u-mb-4 {
  margin-bottom: 1.25rem;
}
@media (min-width: 1400px) {
  .u-mb-4\@xxl {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-4\@xl {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-4\@lg {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-4\@md {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-4\@sm {
    margin-bottom: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-mb-4\@xs {
    margin-bottom: 1.25rem;
  }
}

.u-mb-5 {
  margin-bottom: 1.5rem;
}
@media (min-width: 1400px) {
  .u-mb-5\@xxl {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-5\@xl {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-5\@lg {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-5\@md {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-5\@sm {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-mb-5\@xs {
    margin-bottom: 1.5rem;
  }
}

.u-mb-6 {
  margin-bottom: 1.75rem;
}
@media (min-width: 1400px) {
  .u-mb-6\@xxl {
    margin-bottom: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-6\@xl {
    margin-bottom: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-6\@lg {
    margin-bottom: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-6\@md {
    margin-bottom: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-6\@sm {
    margin-bottom: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-mb-6\@xs {
    margin-bottom: 1.75rem;
  }
}

.u-mb-7 {
  margin-bottom: 2rem;
}
@media (min-width: 1400px) {
  .u-mb-7\@xxl {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-7\@xl {
    margin-bottom: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-7\@lg {
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-7\@md {
    margin-bottom: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-7\@sm {
    margin-bottom: 2rem;
  }
}
@media (max-width: 575px) {
  .u-mb-7\@xs {
    margin-bottom: 2rem;
  }
}

.u-mb-8 {
  margin-bottom: 2.5rem;
}
@media (min-width: 1400px) {
  .u-mb-8\@xxl {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-8\@xl {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-8\@lg {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-8\@md {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-8\@sm {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-mb-8\@xs {
    margin-bottom: 2.5rem;
  }
}

.u-mb-9 {
  margin-bottom: 3rem;
}
@media (min-width: 1400px) {
  .u-mb-9\@xxl {
    margin-bottom: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-9\@xl {
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-9\@lg {
    margin-bottom: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-9\@md {
    margin-bottom: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-9\@sm {
    margin-bottom: 3rem;
  }
}
@media (max-width: 575px) {
  .u-mb-9\@xs {
    margin-bottom: 3rem;
  }
}

.u-mb-def {
  margin-bottom: 0.875rem;
}
@media (min-width: 1400px) {
  .u-mb-def\@xxl {
    margin-bottom: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-mb-def\@xl {
    margin-bottom: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-mb-def\@lg {
    margin-bottom: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-mb-def\@md {
    margin-bottom: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-mb-def\@sm {
    margin-bottom: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-mb-def\@xs {
    margin-bottom: 0.875rem;
  }
}

.u-ms-0 {
  margin-left: 0rem;
}
@media (min-width: 1400px) {
  .u-ms-0\@xxl {
    margin-left: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-0\@xl {
    margin-left: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-0\@lg {
    margin-left: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-0\@md {
    margin-left: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-0\@sm {
    margin-left: 0rem;
  }
}
@media (max-width: 575px) {
  .u-ms-0\@xs {
    margin-left: 0rem;
  }
}

.u-ms-1 {
  margin-left: 0.25rem;
}
@media (min-width: 1400px) {
  .u-ms-1\@xxl {
    margin-left: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-1\@xl {
    margin-left: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-1\@lg {
    margin-left: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-1\@md {
    margin-left: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-1\@sm {
    margin-left: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-ms-1\@xs {
    margin-left: 0.25rem;
  }
}

.u-ms-2 {
  margin-left: 0.5rem;
}
@media (min-width: 1400px) {
  .u-ms-2\@xxl {
    margin-left: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-2\@xl {
    margin-left: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-2\@lg {
    margin-left: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-2\@md {
    margin-left: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-2\@sm {
    margin-left: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-ms-2\@xs {
    margin-left: 0.5rem;
  }
}

.u-ms-3 {
  margin-left: 1rem;
}
@media (min-width: 1400px) {
  .u-ms-3\@xxl {
    margin-left: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-3\@xl {
    margin-left: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-3\@lg {
    margin-left: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-3\@md {
    margin-left: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-3\@sm {
    margin-left: 1rem;
  }
}
@media (max-width: 575px) {
  .u-ms-3\@xs {
    margin-left: 1rem;
  }
}

.u-ms-4 {
  margin-left: 1.25rem;
}
@media (min-width: 1400px) {
  .u-ms-4\@xxl {
    margin-left: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-4\@xl {
    margin-left: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-4\@lg {
    margin-left: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-4\@md {
    margin-left: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-4\@sm {
    margin-left: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-ms-4\@xs {
    margin-left: 1.25rem;
  }
}

.u-ms-5 {
  margin-left: 1.5rem;
}
@media (min-width: 1400px) {
  .u-ms-5\@xxl {
    margin-left: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-5\@xl {
    margin-left: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-5\@lg {
    margin-left: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-5\@md {
    margin-left: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-5\@sm {
    margin-left: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-ms-5\@xs {
    margin-left: 1.5rem;
  }
}

.u-ms-6 {
  margin-left: 1.75rem;
}
@media (min-width: 1400px) {
  .u-ms-6\@xxl {
    margin-left: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-6\@xl {
    margin-left: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-6\@lg {
    margin-left: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-6\@md {
    margin-left: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-6\@sm {
    margin-left: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-ms-6\@xs {
    margin-left: 1.75rem;
  }
}

.u-ms-7 {
  margin-left: 2rem;
}
@media (min-width: 1400px) {
  .u-ms-7\@xxl {
    margin-left: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-7\@xl {
    margin-left: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-7\@lg {
    margin-left: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-7\@md {
    margin-left: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-7\@sm {
    margin-left: 2rem;
  }
}
@media (max-width: 575px) {
  .u-ms-7\@xs {
    margin-left: 2rem;
  }
}

.u-ms-8 {
  margin-left: 2.5rem;
}
@media (min-width: 1400px) {
  .u-ms-8\@xxl {
    margin-left: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-8\@xl {
    margin-left: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-8\@lg {
    margin-left: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-8\@md {
    margin-left: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-8\@sm {
    margin-left: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-ms-8\@xs {
    margin-left: 2.5rem;
  }
}

.u-ms-9 {
  margin-left: 3rem;
}
@media (min-width: 1400px) {
  .u-ms-9\@xxl {
    margin-left: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-9\@xl {
    margin-left: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-9\@lg {
    margin-left: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-9\@md {
    margin-left: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-9\@sm {
    margin-left: 3rem;
  }
}
@media (max-width: 575px) {
  .u-ms-9\@xs {
    margin-left: 3rem;
  }
}

.u-ms-def {
  margin-left: 0.875rem;
}
@media (min-width: 1400px) {
  .u-ms-def\@xxl {
    margin-left: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ms-def\@xl {
    margin-left: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ms-def\@lg {
    margin-left: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ms-def\@md {
    margin-left: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ms-def\@sm {
    margin-left: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-ms-def\@xs {
    margin-left: 0.875rem;
  }
}

.u-me-0 {
  margin-right: 0rem;
}
@media (min-width: 1400px) {
  .u-me-0\@xxl {
    margin-right: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-0\@xl {
    margin-right: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-0\@lg {
    margin-right: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-0\@md {
    margin-right: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-0\@sm {
    margin-right: 0rem;
  }
}
@media (max-width: 575px) {
  .u-me-0\@xs {
    margin-right: 0rem;
  }
}

.u-me-1 {
  margin-right: 0.25rem;
}
@media (min-width: 1400px) {
  .u-me-1\@xxl {
    margin-right: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-1\@xl {
    margin-right: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-1\@lg {
    margin-right: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-1\@md {
    margin-right: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-1\@sm {
    margin-right: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-me-1\@xs {
    margin-right: 0.25rem;
  }
}

.u-me-2 {
  margin-right: 0.5rem;
}
@media (min-width: 1400px) {
  .u-me-2\@xxl {
    margin-right: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-2\@xl {
    margin-right: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-2\@lg {
    margin-right: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-2\@md {
    margin-right: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-2\@sm {
    margin-right: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-me-2\@xs {
    margin-right: 0.5rem;
  }
}

.u-me-3 {
  margin-right: 1rem;
}
@media (min-width: 1400px) {
  .u-me-3\@xxl {
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-3\@xl {
    margin-right: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-3\@lg {
    margin-right: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-3\@md {
    margin-right: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-3\@sm {
    margin-right: 1rem;
  }
}
@media (max-width: 575px) {
  .u-me-3\@xs {
    margin-right: 1rem;
  }
}

.u-me-4 {
  margin-right: 1.25rem;
}
@media (min-width: 1400px) {
  .u-me-4\@xxl {
    margin-right: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-4\@xl {
    margin-right: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-4\@lg {
    margin-right: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-4\@md {
    margin-right: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-4\@sm {
    margin-right: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-me-4\@xs {
    margin-right: 1.25rem;
  }
}

.u-me-5 {
  margin-right: 1.5rem;
}
@media (min-width: 1400px) {
  .u-me-5\@xxl {
    margin-right: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-5\@xl {
    margin-right: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-5\@lg {
    margin-right: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-5\@md {
    margin-right: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-5\@sm {
    margin-right: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-me-5\@xs {
    margin-right: 1.5rem;
  }
}

.u-me-6 {
  margin-right: 1.75rem;
}
@media (min-width: 1400px) {
  .u-me-6\@xxl {
    margin-right: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-6\@xl {
    margin-right: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-6\@lg {
    margin-right: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-6\@md {
    margin-right: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-6\@sm {
    margin-right: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-me-6\@xs {
    margin-right: 1.75rem;
  }
}

.u-me-7 {
  margin-right: 2rem;
}
@media (min-width: 1400px) {
  .u-me-7\@xxl {
    margin-right: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-7\@xl {
    margin-right: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-7\@lg {
    margin-right: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-7\@md {
    margin-right: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-7\@sm {
    margin-right: 2rem;
  }
}
@media (max-width: 575px) {
  .u-me-7\@xs {
    margin-right: 2rem;
  }
}

.u-me-8 {
  margin-right: 2.5rem;
}
@media (min-width: 1400px) {
  .u-me-8\@xxl {
    margin-right: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-8\@xl {
    margin-right: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-8\@lg {
    margin-right: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-8\@md {
    margin-right: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-8\@sm {
    margin-right: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-me-8\@xs {
    margin-right: 2.5rem;
  }
}

.u-me-9 {
  margin-right: 3rem;
}
@media (min-width: 1400px) {
  .u-me-9\@xxl {
    margin-right: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-9\@xl {
    margin-right: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-9\@lg {
    margin-right: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-9\@md {
    margin-right: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-9\@sm {
    margin-right: 3rem;
  }
}
@media (max-width: 575px) {
  .u-me-9\@xs {
    margin-right: 3rem;
  }
}

.u-me-def {
  margin-right: 0.875rem;
}
@media (min-width: 1400px) {
  .u-me-def\@xxl {
    margin-right: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-me-def\@xl {
    margin-right: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-me-def\@lg {
    margin-right: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-me-def\@md {
    margin-right: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-me-def\@sm {
    margin-right: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-me-def\@xs {
    margin-right: 0.875rem;
  }
}

.u-mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.u-mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.u-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.u-mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.u-mx-4 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.u-mx-5 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.u-mx-6 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.u-mx-7 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.u-mx-8 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.u-mx-9 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.u-mx-def {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}

.u-my-0 {
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.u-my-1 {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.u-my-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.u-my-3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.u-my-4 {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.u-my-5 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.u-my-6 {
  margin-bottom: 1.75rem;
  margin-top: 1.75rem;
}

.u-my-7 {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.u-my-8 {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}

.u-my-9 {
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.u-my-def {
  margin-bottom: 0.875rem;
  margin-top: 0.875rem;
}

/* PADDING */
.u-p-0 {
  padding: 0rem;
}
@media (min-width: 1400px) {
  .u-p-0\@xxl {
    padding: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-0\@xl {
    padding: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-0\@lg {
    padding: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-0\@md {
    padding: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-0\@sm {
    padding: 0rem;
  }
}
@media (max-width: 575px) {
  .u-p-0\@xs {
    padding: 0rem;
  }
}

.u-p-1 {
  padding: 0.25rem;
}
@media (min-width: 1400px) {
  .u-p-1\@xxl {
    padding: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-1\@xl {
    padding: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-1\@lg {
    padding: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-1\@md {
    padding: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-1\@sm {
    padding: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-p-1\@xs {
    padding: 0.25rem;
  }
}

.u-p-2 {
  padding: 0.5rem;
}
@media (min-width: 1400px) {
  .u-p-2\@xxl {
    padding: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-2\@xl {
    padding: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-2\@lg {
    padding: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-2\@md {
    padding: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-2\@sm {
    padding: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-p-2\@xs {
    padding: 0.5rem;
  }
}

.u-p-3 {
  padding: 1rem;
}
@media (min-width: 1400px) {
  .u-p-3\@xxl {
    padding: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-3\@xl {
    padding: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-3\@lg {
    padding: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-3\@md {
    padding: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-3\@sm {
    padding: 1rem;
  }
}
@media (max-width: 575px) {
  .u-p-3\@xs {
    padding: 1rem;
  }
}

.u-p-4 {
  padding: 1.25rem;
}
@media (min-width: 1400px) {
  .u-p-4\@xxl {
    padding: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-4\@xl {
    padding: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-4\@lg {
    padding: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-4\@md {
    padding: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-4\@sm {
    padding: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-p-4\@xs {
    padding: 1.25rem;
  }
}

.u-p-5 {
  padding: 1.5rem;
}
@media (min-width: 1400px) {
  .u-p-5\@xxl {
    padding: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-5\@xl {
    padding: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-5\@lg {
    padding: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-5\@md {
    padding: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-5\@sm {
    padding: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-p-5\@xs {
    padding: 1.5rem;
  }
}

.u-p-6 {
  padding: 1.75rem;
}
@media (min-width: 1400px) {
  .u-p-6\@xxl {
    padding: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-6\@xl {
    padding: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-6\@lg {
    padding: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-6\@md {
    padding: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-6\@sm {
    padding: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-p-6\@xs {
    padding: 1.75rem;
  }
}

.u-p-7 {
  padding: 2rem;
}
@media (min-width: 1400px) {
  .u-p-7\@xxl {
    padding: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-7\@xl {
    padding: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-7\@lg {
    padding: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-7\@md {
    padding: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-7\@sm {
    padding: 2rem;
  }
}
@media (max-width: 575px) {
  .u-p-7\@xs {
    padding: 2rem;
  }
}

.u-p-8 {
  padding: 2.5rem;
}
@media (min-width: 1400px) {
  .u-p-8\@xxl {
    padding: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-8\@xl {
    padding: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-8\@lg {
    padding: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-8\@md {
    padding: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-8\@sm {
    padding: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-p-8\@xs {
    padding: 2.5rem;
  }
}

.u-p-9 {
  padding: 3rem;
}
@media (min-width: 1400px) {
  .u-p-9\@xxl {
    padding: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-9\@xl {
    padding: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-9\@lg {
    padding: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-9\@md {
    padding: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-9\@sm {
    padding: 3rem;
  }
}
@media (max-width: 575px) {
  .u-p-9\@xs {
    padding: 3rem;
  }
}

.u-p-def {
  padding: 0.875rem;
}
@media (min-width: 1400px) {
  .u-p-def\@xxl {
    padding: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-p-def\@xl {
    padding: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-p-def\@lg {
    padding: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-p-def\@md {
    padding: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-p-def\@sm {
    padding: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-p-def\@xs {
    padding: 0.875rem;
  }
}

.u-pt-0 {
  padding-top: 0rem;
}
@media (min-width: 1400px) {
  .u-pt-0\@xxl {
    padding-top: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-0\@xl {
    padding-top: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-0\@lg {
    padding-top: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-0\@md {
    padding-top: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-0\@sm {
    padding-top: 0rem;
  }
}
@media (max-width: 575px) {
  .u-pt-0\@xs {
    padding-top: 0rem;
  }
}

.u-pt-1 {
  padding-top: 0.25rem;
}
@media (min-width: 1400px) {
  .u-pt-1\@xxl {
    padding-top: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-1\@xl {
    padding-top: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-1\@lg {
    padding-top: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-1\@md {
    padding-top: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-1\@sm {
    padding-top: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-pt-1\@xs {
    padding-top: 0.25rem;
  }
}

.u-pt-2 {
  padding-top: 0.5rem;
}
@media (min-width: 1400px) {
  .u-pt-2\@xxl {
    padding-top: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-2\@xl {
    padding-top: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-2\@lg {
    padding-top: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-2\@md {
    padding-top: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-2\@sm {
    padding-top: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-pt-2\@xs {
    padding-top: 0.5rem;
  }
}

.u-pt-3 {
  padding-top: 1rem;
}
@media (min-width: 1400px) {
  .u-pt-3\@xxl {
    padding-top: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-3\@xl {
    padding-top: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-3\@lg {
    padding-top: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-3\@md {
    padding-top: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-3\@sm {
    padding-top: 1rem;
  }
}
@media (max-width: 575px) {
  .u-pt-3\@xs {
    padding-top: 1rem;
  }
}

.u-pt-4 {
  padding-top: 1.25rem;
}
@media (min-width: 1400px) {
  .u-pt-4\@xxl {
    padding-top: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-4\@xl {
    padding-top: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-4\@lg {
    padding-top: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-4\@md {
    padding-top: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-4\@sm {
    padding-top: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-pt-4\@xs {
    padding-top: 1.25rem;
  }
}

.u-pt-5 {
  padding-top: 1.5rem;
}
@media (min-width: 1400px) {
  .u-pt-5\@xxl {
    padding-top: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-5\@xl {
    padding-top: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-5\@lg {
    padding-top: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-5\@md {
    padding-top: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-5\@sm {
    padding-top: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-pt-5\@xs {
    padding-top: 1.5rem;
  }
}

.u-pt-6 {
  padding-top: 1.75rem;
}
@media (min-width: 1400px) {
  .u-pt-6\@xxl {
    padding-top: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-6\@xl {
    padding-top: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-6\@lg {
    padding-top: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-6\@md {
    padding-top: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-6\@sm {
    padding-top: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-pt-6\@xs {
    padding-top: 1.75rem;
  }
}

.u-pt-7 {
  padding-top: 2rem;
}
@media (min-width: 1400px) {
  .u-pt-7\@xxl {
    padding-top: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-7\@xl {
    padding-top: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-7\@lg {
    padding-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-7\@md {
    padding-top: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-7\@sm {
    padding-top: 2rem;
  }
}
@media (max-width: 575px) {
  .u-pt-7\@xs {
    padding-top: 2rem;
  }
}

.u-pt-8 {
  padding-top: 2.5rem;
}
@media (min-width: 1400px) {
  .u-pt-8\@xxl {
    padding-top: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-8\@xl {
    padding-top: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-8\@lg {
    padding-top: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-8\@md {
    padding-top: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-8\@sm {
    padding-top: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-pt-8\@xs {
    padding-top: 2.5rem;
  }
}

.u-pt-9 {
  padding-top: 3rem;
}
@media (min-width: 1400px) {
  .u-pt-9\@xxl {
    padding-top: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-9\@xl {
    padding-top: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-9\@lg {
    padding-top: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-9\@md {
    padding-top: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-9\@sm {
    padding-top: 3rem;
  }
}
@media (max-width: 575px) {
  .u-pt-9\@xs {
    padding-top: 3rem;
  }
}

.u-pt-def {
  padding-top: 0.875rem;
}
@media (min-width: 1400px) {
  .u-pt-def\@xxl {
    padding-top: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pt-def\@xl {
    padding-top: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pt-def\@lg {
    padding-top: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pt-def\@md {
    padding-top: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pt-def\@sm {
    padding-top: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-pt-def\@xs {
    padding-top: 0.875rem;
  }
}

.u-pb-0 {
  padding-bottom: 0rem;
}
@media (min-width: 1400px) {
  .u-pb-0\@xxl {
    padding-bottom: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-0\@xl {
    padding-bottom: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-0\@lg {
    padding-bottom: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-0\@md {
    padding-bottom: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-0\@sm {
    padding-bottom: 0rem;
  }
}
@media (max-width: 575px) {
  .u-pb-0\@xs {
    padding-bottom: 0rem;
  }
}

.u-pb-1 {
  padding-bottom: 0.25rem;
}
@media (min-width: 1400px) {
  .u-pb-1\@xxl {
    padding-bottom: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-1\@xl {
    padding-bottom: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-1\@lg {
    padding-bottom: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-1\@md {
    padding-bottom: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-1\@sm {
    padding-bottom: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-pb-1\@xs {
    padding-bottom: 0.25rem;
  }
}

.u-pb-2 {
  padding-bottom: 0.5rem;
}
@media (min-width: 1400px) {
  .u-pb-2\@xxl {
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-2\@xl {
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-2\@lg {
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-2\@md {
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-2\@sm {
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-pb-2\@xs {
    padding-bottom: 0.5rem;
  }
}

.u-pb-3 {
  padding-bottom: 1rem;
}
@media (min-width: 1400px) {
  .u-pb-3\@xxl {
    padding-bottom: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-3\@xl {
    padding-bottom: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-3\@lg {
    padding-bottom: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-3\@md {
    padding-bottom: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-3\@sm {
    padding-bottom: 1rem;
  }
}
@media (max-width: 575px) {
  .u-pb-3\@xs {
    padding-bottom: 1rem;
  }
}

.u-pb-4 {
  padding-bottom: 1.25rem;
}
@media (min-width: 1400px) {
  .u-pb-4\@xxl {
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-4\@xl {
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-4\@lg {
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-4\@md {
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-4\@sm {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-pb-4\@xs {
    padding-bottom: 1.25rem;
  }
}

.u-pb-5 {
  padding-bottom: 1.5rem;
}
@media (min-width: 1400px) {
  .u-pb-5\@xxl {
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-5\@xl {
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-5\@lg {
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-5\@md {
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-5\@sm {
    padding-bottom: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-pb-5\@xs {
    padding-bottom: 1.5rem;
  }
}

.u-pb-6 {
  padding-bottom: 1.75rem;
}
@media (min-width: 1400px) {
  .u-pb-6\@xxl {
    padding-bottom: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-6\@xl {
    padding-bottom: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-6\@lg {
    padding-bottom: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-6\@md {
    padding-bottom: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-6\@sm {
    padding-bottom: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-pb-6\@xs {
    padding-bottom: 1.75rem;
  }
}

.u-pb-7 {
  padding-bottom: 2rem;
}
@media (min-width: 1400px) {
  .u-pb-7\@xxl {
    padding-bottom: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-7\@xl {
    padding-bottom: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-7\@lg {
    padding-bottom: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-7\@md {
    padding-bottom: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-7\@sm {
    padding-bottom: 2rem;
  }
}
@media (max-width: 575px) {
  .u-pb-7\@xs {
    padding-bottom: 2rem;
  }
}

.u-pb-8 {
  padding-bottom: 2.5rem;
}
@media (min-width: 1400px) {
  .u-pb-8\@xxl {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-8\@xl {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-8\@lg {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-8\@md {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-8\@sm {
    padding-bottom: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-pb-8\@xs {
    padding-bottom: 2.5rem;
  }
}

.u-pb-9 {
  padding-bottom: 3rem;
}
@media (min-width: 1400px) {
  .u-pb-9\@xxl {
    padding-bottom: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-9\@xl {
    padding-bottom: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-9\@lg {
    padding-bottom: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-9\@md {
    padding-bottom: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-9\@sm {
    padding-bottom: 3rem;
  }
}
@media (max-width: 575px) {
  .u-pb-9\@xs {
    padding-bottom: 3rem;
  }
}

.u-pb-def {
  padding-bottom: 0.875rem;
}
@media (min-width: 1400px) {
  .u-pb-def\@xxl {
    padding-bottom: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pb-def\@xl {
    padding-bottom: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pb-def\@lg {
    padding-bottom: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pb-def\@md {
    padding-bottom: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pb-def\@sm {
    padding-bottom: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-pb-def\@xs {
    padding-bottom: 0.875rem;
  }
}

.u-ps-0 {
  padding-left: 0rem;
}
@media (min-width: 1400px) {
  .u-ps-0\@xxl {
    padding-left: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-0\@xl {
    padding-left: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-0\@lg {
    padding-left: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-0\@md {
    padding-left: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-0\@sm {
    padding-left: 0rem;
  }
}
@media (max-width: 575px) {
  .u-ps-0\@xs {
    padding-left: 0rem;
  }
}

.u-ps-1 {
  padding-left: 0.25rem;
}
@media (min-width: 1400px) {
  .u-ps-1\@xxl {
    padding-left: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-1\@xl {
    padding-left: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-1\@lg {
    padding-left: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-1\@md {
    padding-left: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-1\@sm {
    padding-left: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-ps-1\@xs {
    padding-left: 0.25rem;
  }
}

.u-ps-2 {
  padding-left: 0.5rem;
}
@media (min-width: 1400px) {
  .u-ps-2\@xxl {
    padding-left: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-2\@xl {
    padding-left: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-2\@lg {
    padding-left: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-2\@md {
    padding-left: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-2\@sm {
    padding-left: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-ps-2\@xs {
    padding-left: 0.5rem;
  }
}

.u-ps-3 {
  padding-left: 1rem;
}
@media (min-width: 1400px) {
  .u-ps-3\@xxl {
    padding-left: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-3\@xl {
    padding-left: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-3\@lg {
    padding-left: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-3\@md {
    padding-left: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-3\@sm {
    padding-left: 1rem;
  }
}
@media (max-width: 575px) {
  .u-ps-3\@xs {
    padding-left: 1rem;
  }
}

.u-ps-4 {
  padding-left: 1.25rem;
}
@media (min-width: 1400px) {
  .u-ps-4\@xxl {
    padding-left: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-4\@xl {
    padding-left: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-4\@lg {
    padding-left: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-4\@md {
    padding-left: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-4\@sm {
    padding-left: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-ps-4\@xs {
    padding-left: 1.25rem;
  }
}

.u-ps-5 {
  padding-left: 1.5rem;
}
@media (min-width: 1400px) {
  .u-ps-5\@xxl {
    padding-left: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-5\@xl {
    padding-left: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-5\@lg {
    padding-left: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-5\@md {
    padding-left: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-5\@sm {
    padding-left: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-ps-5\@xs {
    padding-left: 1.5rem;
  }
}

.u-ps-6 {
  padding-left: 1.75rem;
}
@media (min-width: 1400px) {
  .u-ps-6\@xxl {
    padding-left: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-6\@xl {
    padding-left: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-6\@lg {
    padding-left: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-6\@md {
    padding-left: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-6\@sm {
    padding-left: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-ps-6\@xs {
    padding-left: 1.75rem;
  }
}

.u-ps-7 {
  padding-left: 2rem;
}
@media (min-width: 1400px) {
  .u-ps-7\@xxl {
    padding-left: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-7\@xl {
    padding-left: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-7\@lg {
    padding-left: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-7\@md {
    padding-left: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-7\@sm {
    padding-left: 2rem;
  }
}
@media (max-width: 575px) {
  .u-ps-7\@xs {
    padding-left: 2rem;
  }
}

.u-ps-8 {
  padding-left: 2.5rem;
}
@media (min-width: 1400px) {
  .u-ps-8\@xxl {
    padding-left: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-8\@xl {
    padding-left: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-8\@lg {
    padding-left: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-8\@md {
    padding-left: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-8\@sm {
    padding-left: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-ps-8\@xs {
    padding-left: 2.5rem;
  }
}

.u-ps-9 {
  padding-left: 3rem;
}
@media (min-width: 1400px) {
  .u-ps-9\@xxl {
    padding-left: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-9\@xl {
    padding-left: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-9\@lg {
    padding-left: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-9\@md {
    padding-left: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-9\@sm {
    padding-left: 3rem;
  }
}
@media (max-width: 575px) {
  .u-ps-9\@xs {
    padding-left: 3rem;
  }
}

.u-ps-def {
  padding-left: 0.875rem;
}
@media (min-width: 1400px) {
  .u-ps-def\@xxl {
    padding-left: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-ps-def\@xl {
    padding-left: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-ps-def\@lg {
    padding-left: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-ps-def\@md {
    padding-left: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-ps-def\@sm {
    padding-left: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-ps-def\@xs {
    padding-left: 0.875rem;
  }
}

.u-pe-0 {
  padding-right: 0rem;
}
@media (min-width: 1400px) {
  .u-pe-0\@xxl {
    padding-right: 0rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-0\@xl {
    padding-right: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-0\@lg {
    padding-right: 0rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-0\@md {
    padding-right: 0rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-0\@sm {
    padding-right: 0rem;
  }
}
@media (max-width: 575px) {
  .u-pe-0\@xs {
    padding-right: 0rem;
  }
}

.u-pe-1 {
  padding-right: 0.25rem;
}
@media (min-width: 1400px) {
  .u-pe-1\@xxl {
    padding-right: 0.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-1\@xl {
    padding-right: 0.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-1\@lg {
    padding-right: 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-1\@md {
    padding-right: 0.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-1\@sm {
    padding-right: 0.25rem;
  }
}
@media (max-width: 575px) {
  .u-pe-1\@xs {
    padding-right: 0.25rem;
  }
}

.u-pe-2 {
  padding-right: 0.5rem;
}
@media (min-width: 1400px) {
  .u-pe-2\@xxl {
    padding-right: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-2\@xl {
    padding-right: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-2\@lg {
    padding-right: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-2\@md {
    padding-right: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-2\@sm {
    padding-right: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-pe-2\@xs {
    padding-right: 0.5rem;
  }
}

.u-pe-3 {
  padding-right: 1rem;
}
@media (min-width: 1400px) {
  .u-pe-3\@xxl {
    padding-right: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-3\@xl {
    padding-right: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-3\@lg {
    padding-right: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-3\@md {
    padding-right: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-3\@sm {
    padding-right: 1rem;
  }
}
@media (max-width: 575px) {
  .u-pe-3\@xs {
    padding-right: 1rem;
  }
}

.u-pe-4 {
  padding-right: 1.25rem;
}
@media (min-width: 1400px) {
  .u-pe-4\@xxl {
    padding-right: 1.25rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-4\@xl {
    padding-right: 1.25rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-4\@lg {
    padding-right: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-4\@md {
    padding-right: 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-4\@sm {
    padding-right: 1.25rem;
  }
}
@media (max-width: 575px) {
  .u-pe-4\@xs {
    padding-right: 1.25rem;
  }
}

.u-pe-5 {
  padding-right: 1.5rem;
}
@media (min-width: 1400px) {
  .u-pe-5\@xxl {
    padding-right: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-5\@xl {
    padding-right: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-5\@lg {
    padding-right: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-5\@md {
    padding-right: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-5\@sm {
    padding-right: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-pe-5\@xs {
    padding-right: 1.5rem;
  }
}

.u-pe-6 {
  padding-right: 1.75rem;
}
@media (min-width: 1400px) {
  .u-pe-6\@xxl {
    padding-right: 1.75rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-6\@xl {
    padding-right: 1.75rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-6\@lg {
    padding-right: 1.75rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-6\@md {
    padding-right: 1.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-6\@sm {
    padding-right: 1.75rem;
  }
}
@media (max-width: 575px) {
  .u-pe-6\@xs {
    padding-right: 1.75rem;
  }
}

.u-pe-7 {
  padding-right: 2rem;
}
@media (min-width: 1400px) {
  .u-pe-7\@xxl {
    padding-right: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-7\@xl {
    padding-right: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-7\@lg {
    padding-right: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-7\@md {
    padding-right: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-7\@sm {
    padding-right: 2rem;
  }
}
@media (max-width: 575px) {
  .u-pe-7\@xs {
    padding-right: 2rem;
  }
}

.u-pe-8 {
  padding-right: 2.5rem;
}
@media (min-width: 1400px) {
  .u-pe-8\@xxl {
    padding-right: 2.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-8\@xl {
    padding-right: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-8\@lg {
    padding-right: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-8\@md {
    padding-right: 2.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-8\@sm {
    padding-right: 2.5rem;
  }
}
@media (max-width: 575px) {
  .u-pe-8\@xs {
    padding-right: 2.5rem;
  }
}

.u-pe-9 {
  padding-right: 3rem;
}
@media (min-width: 1400px) {
  .u-pe-9\@xxl {
    padding-right: 3rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-9\@xl {
    padding-right: 3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-9\@lg {
    padding-right: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-9\@md {
    padding-right: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-9\@sm {
    padding-right: 3rem;
  }
}
@media (max-width: 575px) {
  .u-pe-9\@xs {
    padding-right: 3rem;
  }
}

.u-pe-def {
  padding-right: 0.875rem;
}
@media (min-width: 1400px) {
  .u-pe-def\@xxl {
    padding-right: 0.875rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-pe-def\@xl {
    padding-right: 0.875rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-pe-def\@lg {
    padding-right: 0.875rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-pe-def\@md {
    padding-right: 0.875rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-pe-def\@sm {
    padding-right: 0.875rem;
  }
}
@media (max-width: 575px) {
  .u-pe-def\@xs {
    padding-right: 0.875rem;
  }
}

.u-px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.u-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.u-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.u-px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.u-px-4 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.u-px-5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.u-px-6 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.u-px-7 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.u-px-8 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.u-px-9 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.u-px-def {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.u-py-0 {
  padding-bottom: 0rem;
  padding-top: 0rem;
}

.u-py-1 {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.u-py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.u-py-3 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.u-py-4 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.u-py-5 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.u-py-6 {
  padding-bottom: 1.75rem;
  padding-top: 1.75rem;
}

.u-py-7 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.u-py-8 {
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.u-py-9 {
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.u-py-def {
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;
}

.u-m-centered {
  margin: 0 auto;
}

/* BORDER LISTS */
/* UTILITIES */
.u-border {
  border-color: #F2F2F2;
  border-style: solid;
  border-width: 0.0625rem;
}
.u-border-0 {
  border-width: 0rem;
}
.u-border-1 {
  border-width: 0.125rem;
}
.u-border-2 {
  border-width: 0.25rem;
}
.u-border-3 {
  border-width: 0.375rem;
}
.u-border-4 {
  border-width: 0.5rem;
}
.u-border-none {
  border-style: none;
}
.u-border-hidden {
  border-style: hidden;
}
.u-border-dotted {
  border-style: dotted;
}
.u-border-dashed {
  border-style: dashed;
}
.u-border-solid {
  border-style: solid;
}
.u-border-double {
  border-style: double;
}
.u-border-groove {
  border-style: groove;
}
.u-border-ridge {
  border-style: ridge;
}
.u-border-inset {
  border-style: inset;
}
.u-border-outset {
  border-style: outset;
}
.u-border-primary1 {
  border-color: #001871 !important;
}
.u-border-primary2 {
  border-color: #B9C6D4 !important;
}
.u-border-primary3 {
  border-color: #337AB7 !important;
}
.u-border-secondary1 {
  border-color: #333333 !important;
}
.u-border-secondary2 {
  border-color: #4A4A49 !important;
}
.u-border-disabled1 {
  border-color: #F2F6FC !important;
}
.u-border-over1 {
  border-color: #0036FF !important;
}
.u-border-selected1 {
  border-color: #1352DE !important;
}
.u-border-disabled2 {
  border-color: #DFE5EC !important;
}
.u-border-over2 {
  border-color: #617E9E !important;
}
.u-border-selected2 {
  border-color: #5D738D !important;
}
.u-border-disabled3 {
  border-color: #D7E7F4 !important;
}
.u-border-over3 {
  border-color: #347DBB !important;
}
.u-border-comp1 {
  border-color: #EBF1FB !important;
}
.u-border-comp2 {
  border-color: #F6F6F6 !important;
}
.u-border-comp3 {
  border-color: #D0D6D3 !important;
}
.u-border-comp4 {
  border-color: #116399 !important;
}
.u-border-warning-error {
  border-color: #BF0019 !important;
}
.u-border-notice {
  border-color: #EA9C0B !important;
}
.u-border-info {
  border-color: #007BFF !important;
}
.u-border-success {
  border-color: #62DA70 !important;
}
.u-border-grey100 {
  border-color: #FCFCFC !important;
}
.u-border-grey200 {
  border-color: #F2F2F2 !important;
}
.u-border-grey300 {
  border-color: #C4C4C4 !important;
}
.u-border-grey400 {
  border-color: #A8A8A8 !important;
}
.u-border-grey500 {
  border-color: #8C8C8C !important;
}
.u-border-grey600 {
  border-color: #707070 !important;
}
.u-border-grey700 {
  border-color: #545454 !important;
}
.u-border-grey800 {
  border-color: #383838 !important;
}
.u-border-grey900 {
  border-color: #1C1C1C !important;
}
.u-border-black {
  border-color: #000000 !important;
}
.u-border-white {
  border-color: #FFF !important;
}
.u-border-transparent {
  border-color: transparent !important;
}
.u-border-inherit {
  border-color: inherit !important;
}

.u-border-top {
  border-top-color: #F2F2F2;
  border-top-style: solid;
  border-top-width: 0.0625rem;
}

.u-border-top-0 {
  border-top-width: 0rem;
}

.u-border-top-1 {
  border-top-width: 0.125rem;
}

.u-border-top-2 {
  border-top-width: 0.25rem;
}

.u-border-top-3 {
  border-top-width: 0.375rem;
}

.u-border-top-4 {
  border-top-width: 0.5rem;
}

.u-border-top-none {
  border-top-style: none;
}

.u-border-top-hidden {
  border-top-style: hidden;
}

.u-border-top-dotted {
  border-top-style: dotted;
}

.u-border-top-dashed {
  border-top-style: dashed;
}

.u-border-top-solid {
  border-top-style: solid;
}

.u-border-top-double {
  border-top-style: double;
}

.u-border-top-groove {
  border-top-style: groove;
}

.u-border-top-ridge {
  border-top-style: ridge;
}

.u-border-top-inset {
  border-top-style: inset;
}

.u-border-top-outset {
  border-top-style: outset;
}

.u-border-top-primary1 {
  border-top-color: #001871;
}

.u-border-top-primary2 {
  border-top-color: #B9C6D4;
}

.u-border-top-primary3 {
  border-top-color: #337AB7;
}

.u-border-top-secondary1 {
  border-top-color: #333333;
}

.u-border-top-secondary2 {
  border-top-color: #4A4A49;
}

.u-border-top-disabled1 {
  border-top-color: #F2F6FC;
}

.u-border-top-over1 {
  border-top-color: #0036FF;
}

.u-border-top-selected1 {
  border-top-color: #1352DE;
}

.u-border-top-disabled2 {
  border-top-color: #DFE5EC;
}

.u-border-top-over2 {
  border-top-color: #617E9E;
}

.u-border-top-selected2 {
  border-top-color: #5D738D;
}

.u-border-top-disabled3 {
  border-top-color: #D7E7F4;
}

.u-border-top-over3 {
  border-top-color: #347DBB;
}

.u-border-top-comp1 {
  border-top-color: #EBF1FB;
}

.u-border-top-comp2 {
  border-top-color: #F6F6F6;
}

.u-border-top-comp3 {
  border-top-color: #D0D6D3;
}

.u-border-top-comp4 {
  border-top-color: #116399;
}

.u-border-top-warning-error {
  border-top-color: #BF0019;
}

.u-border-top-notice {
  border-top-color: #EA9C0B;
}

.u-border-top-info {
  border-top-color: #007BFF;
}

.u-border-top-success {
  border-top-color: #62DA70;
}

.u-border-top-grey100 {
  border-top-color: #FCFCFC;
}

.u-border-top-grey200 {
  border-top-color: #F2F2F2;
}

.u-border-top-grey300 {
  border-top-color: #C4C4C4;
}

.u-border-top-grey400 {
  border-top-color: #A8A8A8;
}

.u-border-top-grey500 {
  border-top-color: #8C8C8C;
}

.u-border-top-grey600 {
  border-top-color: #707070;
}

.u-border-top-grey700 {
  border-top-color: #545454;
}

.u-border-top-grey800 {
  border-top-color: #383838;
}

.u-border-top-grey900 {
  border-top-color: #1C1C1C;
}

.u-border-top-black {
  border-top-color: #000000;
}

.u-border-top-white {
  border-top-color: #FFF;
}

.u-border-top-transparent {
  border-top-color: transparent;
}

.u-border-top-inherit {
  border-top-color: inherit;
}

.u-border-bottom {
  border-bottom-color: #F2F2F2;
  border-bottom-style: solid;
  border-bottom-width: 0.0625rem;
}

.u-border-bottom-0 {
  border-bottom-width: 0rem;
}

.u-border-bottom-1 {
  border-bottom-width: 0.125rem;
}

.u-border-bottom-2 {
  border-bottom-width: 0.25rem;
}

.u-border-bottom-3 {
  border-bottom-width: 0.375rem;
}

.u-border-bottom-4 {
  border-bottom-width: 0.5rem;
}

.u-border-bottom-none {
  border-bottom-style: none;
}

.u-border-bottom-hidden {
  border-bottom-style: hidden;
}

.u-border-bottom-dotted {
  border-bottom-style: dotted;
}

.u-border-bottom-dashed {
  border-bottom-style: dashed;
}

.u-border-bottom-solid {
  border-bottom-style: solid;
}

.u-border-bottom-double {
  border-bottom-style: double;
}

.u-border-bottom-groove {
  border-bottom-style: groove;
}

.u-border-bottom-ridge {
  border-bottom-style: ridge;
}

.u-border-bottom-inset {
  border-bottom-style: inset;
}

.u-border-bottom-outset {
  border-bottom-style: outset;
}

.u-border-bottom-primary1 {
  border-bottom-color: #001871;
}

.u-border-bottom-primary2 {
  border-bottom-color: #B9C6D4;
}

.u-border-bottom-primary3 {
  border-bottom-color: #337AB7;
}

.u-border-bottom-secondary1 {
  border-bottom-color: #333333;
}

.u-border-bottom-secondary2 {
  border-bottom-color: #4A4A49;
}

.u-border-bottom-disabled1 {
  border-bottom-color: #F2F6FC;
}

.u-border-bottom-over1 {
  border-bottom-color: #0036FF;
}

.u-border-bottom-selected1 {
  border-bottom-color: #1352DE;
}

.u-border-bottom-disabled2 {
  border-bottom-color: #DFE5EC;
}

.u-border-bottom-over2 {
  border-bottom-color: #617E9E;
}

.u-border-bottom-selected2 {
  border-bottom-color: #5D738D;
}

.u-border-bottom-disabled3 {
  border-bottom-color: #D7E7F4;
}

.u-border-bottom-over3 {
  border-bottom-color: #347DBB;
}

.u-border-bottom-comp1 {
  border-bottom-color: #EBF1FB;
}

.u-border-bottom-comp2 {
  border-bottom-color: #F6F6F6;
}

.u-border-bottom-comp3 {
  border-bottom-color: #D0D6D3;
}

.u-border-bottom-comp4 {
  border-bottom-color: #116399;
}

.u-border-bottom-warning-error {
  border-bottom-color: #BF0019;
}

.u-border-bottom-notice {
  border-bottom-color: #EA9C0B;
}

.u-border-bottom-info {
  border-bottom-color: #007BFF;
}

.u-border-bottom-success {
  border-bottom-color: #62DA70;
}

.u-border-bottom-grey100 {
  border-bottom-color: #FCFCFC;
}

.u-border-bottom-grey200 {
  border-bottom-color: #F2F2F2;
}

.u-border-bottom-grey300 {
  border-bottom-color: #C4C4C4;
}

.u-border-bottom-grey400 {
  border-bottom-color: #A8A8A8;
}

.u-border-bottom-grey500 {
  border-bottom-color: #8C8C8C;
}

.u-border-bottom-grey600 {
  border-bottom-color: #707070;
}

.u-border-bottom-grey700 {
  border-bottom-color: #545454;
}

.u-border-bottom-grey800 {
  border-bottom-color: #383838;
}

.u-border-bottom-grey900 {
  border-bottom-color: #1C1C1C;
}

.u-border-bottom-black {
  border-bottom-color: #000000;
}

.u-border-bottom-white {
  border-bottom-color: #FFF;
}

.u-border-bottom-transparent {
  border-bottom-color: transparent;
}

.u-border-bottom-inherit {
  border-bottom-color: inherit;
}

.u-border-start {
  border-left-color: #F2F2F2;
  border-left-style: solid;
  border-left-width: 0.0625rem;
}

.u-border-start-0 {
  border-left-width: 0rem;
}

.u-border-start-1 {
  border-left-width: 0.125rem;
}

.u-border-start-2 {
  border-left-width: 0.25rem;
}

.u-border-start-3 {
  border-left-width: 0.375rem;
}

.u-border-start-4 {
  border-left-width: 0.5rem;
}

.u-border-start-none {
  border-left-style: none;
}

.u-border-start-hidden {
  border-left-style: hidden;
}

.u-border-start-dotted {
  border-left-style: dotted;
}

.u-border-start-dashed {
  border-left-style: dashed;
}

.u-border-start-solid {
  border-left-style: solid;
}

.u-border-start-double {
  border-left-style: double;
}

.u-border-start-groove {
  border-left-style: groove;
}

.u-border-start-ridge {
  border-left-style: ridge;
}

.u-border-start-inset {
  border-left-style: inset;
}

.u-border-start-outset {
  border-left-style: outset;
}

.u-border-start-primary1 {
  border-left-color: #001871;
}

.u-border-start-primary2 {
  border-left-color: #B9C6D4;
}

.u-border-start-primary3 {
  border-left-color: #337AB7;
}

.u-border-start-secondary1 {
  border-left-color: #333333;
}

.u-border-start-secondary2 {
  border-left-color: #4A4A49;
}

.u-border-start-disabled1 {
  border-left-color: #F2F6FC;
}

.u-border-start-over1 {
  border-left-color: #0036FF;
}

.u-border-start-selected1 {
  border-left-color: #1352DE;
}

.u-border-start-disabled2 {
  border-left-color: #DFE5EC;
}

.u-border-start-over2 {
  border-left-color: #617E9E;
}

.u-border-start-selected2 {
  border-left-color: #5D738D;
}

.u-border-start-disabled3 {
  border-left-color: #D7E7F4;
}

.u-border-start-over3 {
  border-left-color: #347DBB;
}

.u-border-start-comp1 {
  border-left-color: #EBF1FB;
}

.u-border-start-comp2 {
  border-left-color: #F6F6F6;
}

.u-border-start-comp3 {
  border-left-color: #D0D6D3;
}

.u-border-start-comp4 {
  border-left-color: #116399;
}

.u-border-start-warning-error {
  border-left-color: #BF0019;
}

.u-border-start-notice {
  border-left-color: #EA9C0B;
}

.u-border-start-info {
  border-left-color: #007BFF;
}

.u-border-start-success {
  border-left-color: #62DA70;
}

.u-border-start-grey100 {
  border-left-color: #FCFCFC;
}

.u-border-start-grey200 {
  border-left-color: #F2F2F2;
}

.u-border-start-grey300 {
  border-left-color: #C4C4C4;
}

.u-border-start-grey400 {
  border-left-color: #A8A8A8;
}

.u-border-start-grey500 {
  border-left-color: #8C8C8C;
}

.u-border-start-grey600 {
  border-left-color: #707070;
}

.u-border-start-grey700 {
  border-left-color: #545454;
}

.u-border-start-grey800 {
  border-left-color: #383838;
}

.u-border-start-grey900 {
  border-left-color: #1C1C1C;
}

.u-border-start-black {
  border-left-color: #000000;
}

.u-border-start-white {
  border-left-color: #FFF;
}

.u-border-start-transparent {
  border-left-color: transparent;
}

.u-border-start-inherit {
  border-left-color: inherit;
}

.u-border-end {
  border-right-color: #F2F2F2;
  border-right-style: solid;
  border-right-width: 0.0625rem;
}

.u-border-end-0 {
  border-right-width: 0rem;
}

.u-border-end-1 {
  border-right-width: 0.125rem;
}

.u-border-end-2 {
  border-right-width: 0.25rem;
}

.u-border-end-3 {
  border-right-width: 0.375rem;
}

.u-border-end-4 {
  border-right-width: 0.5rem;
}

.u-border-end-none {
  border-right-style: none;
}

.u-border-end-hidden {
  border-right-style: hidden;
}

.u-border-end-dotted {
  border-right-style: dotted;
}

.u-border-end-dashed {
  border-right-style: dashed;
}

.u-border-end-solid {
  border-right-style: solid;
}

.u-border-end-double {
  border-right-style: double;
}

.u-border-end-groove {
  border-right-style: groove;
}

.u-border-end-ridge {
  border-right-style: ridge;
}

.u-border-end-inset {
  border-right-style: inset;
}

.u-border-end-outset {
  border-right-style: outset;
}

.u-border-end-primary1 {
  border-right-color: #001871;
}

.u-border-end-primary2 {
  border-right-color: #B9C6D4;
}

.u-border-end-primary3 {
  border-right-color: #337AB7;
}

.u-border-end-secondary1 {
  border-right-color: #333333;
}

.u-border-end-secondary2 {
  border-right-color: #4A4A49;
}

.u-border-end-disabled1 {
  border-right-color: #F2F6FC;
}

.u-border-end-over1 {
  border-right-color: #0036FF;
}

.u-border-end-selected1 {
  border-right-color: #1352DE;
}

.u-border-end-disabled2 {
  border-right-color: #DFE5EC;
}

.u-border-end-over2 {
  border-right-color: #617E9E;
}

.u-border-end-selected2 {
  border-right-color: #5D738D;
}

.u-border-end-disabled3 {
  border-right-color: #D7E7F4;
}

.u-border-end-over3 {
  border-right-color: #347DBB;
}

.u-border-end-comp1 {
  border-right-color: #EBF1FB;
}

.u-border-end-comp2 {
  border-right-color: #F6F6F6;
}

.u-border-end-comp3 {
  border-right-color: #D0D6D3;
}

.u-border-end-comp4 {
  border-right-color: #116399;
}

.u-border-end-warning-error {
  border-right-color: #BF0019;
}

.u-border-end-notice {
  border-right-color: #EA9C0B;
}

.u-border-end-info {
  border-right-color: #007BFF;
}

.u-border-end-success {
  border-right-color: #62DA70;
}

.u-border-end-grey100 {
  border-right-color: #FCFCFC;
}

.u-border-end-grey200 {
  border-right-color: #F2F2F2;
}

.u-border-end-grey300 {
  border-right-color: #C4C4C4;
}

.u-border-end-grey400 {
  border-right-color: #A8A8A8;
}

.u-border-end-grey500 {
  border-right-color: #8C8C8C;
}

.u-border-end-grey600 {
  border-right-color: #707070;
}

.u-border-end-grey700 {
  border-right-color: #545454;
}

.u-border-end-grey800 {
  border-right-color: #383838;
}

.u-border-end-grey900 {
  border-right-color: #1C1C1C;
}

.u-border-end-black {
  border-right-color: #000000;
}

.u-border-end-white {
  border-right-color: #FFF;
}

.u-border-end-transparent {
  border-right-color: transparent;
}

.u-border-end-inherit {
  border-right-color: inherit;
}

/* BORDER RADIUS */
.u-radius-0 {
  border-radius: 0rem !important;
}

.u-radius-1 {
  border-radius: 0.25rem !important;
}

.u-radius-2 {
  border-radius: 0.375rem !important;
}

.u-radius-3 {
  border-radius: 0.5rem !important;
}

.u-radius-4 {
  border-radius: 0.625rem !important;
}

.u-radius-circle {
  border-radius: 3.125rem !important;
}

/* FLOAT UTILITY */
.u-float-start {
  float: left;
}
@media (min-width: 1400px) {
  .u-float-start\@xxl {
    float: left;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-float-start\@xl {
    float: left;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-float-start\@lg {
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-float-start\@md {
    float: left;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-float-start\@sm {
    float: left;
  }
}
@media (max-width: 575px) {
  .u-float-start\@xs {
    float: left;
  }
}

.u-float-end {
  float: right;
}
@media (min-width: 1400px) {
  .u-float-end\@xxl {
    float: right;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-float-end\@xl {
    float: right;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-float-end\@lg {
    float: right;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-float-end\@md {
    float: right;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-float-end\@sm {
    float: right;
  }
}
@media (max-width: 575px) {
  .u-float-end\@xs {
    float: right;
  }
}

.u-float-none {
  float: none;
}
@media (min-width: 1400px) {
  .u-float-none\@xxl {
    float: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-float-none\@xl {
    float: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-float-none\@lg {
    float: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-float-none\@md {
    float: none;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-float-none\@sm {
    float: none;
  }
}
@media (max-width: 575px) {
  .u-float-none\@xs {
    float: none;
  }
}

.u-overf-hidden {
  overflow: hidden;
}
@media (min-width: 1400px) {
  .u-overf-hidden\@xxl {
    overflow: hidden;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overf-hidden\@xl {
    overflow: hidden;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overf-hidden\@lg {
    overflow: hidden;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overf-hidden\@md {
    overflow: hidden;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overf-hidden\@sm {
    overflow: hidden;
  }
}
@media (max-width: 575px) {
  .u-overf-hidden\@xs {
    overflow: hidden;
  }
}

.u-overf-auto {
  overflow: auto;
}
@media (min-width: 1400px) {
  .u-overf-auto\@xxl {
    overflow: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overf-auto\@xl {
    overflow: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overf-auto\@lg {
    overflow: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overf-auto\@md {
    overflow: auto;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overf-auto\@sm {
    overflow: auto;
  }
}
@media (max-width: 575px) {
  .u-overf-auto\@xs {
    overflow: auto;
  }
}

.u-overf-visible {
  overflow: visible;
}
@media (min-width: 1400px) {
  .u-overf-visible\@xxl {
    overflow: visible;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overf-visible\@xl {
    overflow: visible;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overf-visible\@lg {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overf-visible\@md {
    overflow: visible;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overf-visible\@sm {
    overflow: visible;
  }
}
@media (max-width: 575px) {
  .u-overf-visible\@xs {
    overflow: visible;
  }
}

.u-overf-scroll {
  overflow: scroll;
}
@media (min-width: 1400px) {
  .u-overf-scroll\@xxl {
    overflow: scroll;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overf-scroll\@xl {
    overflow: scroll;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overf-scroll\@lg {
    overflow: scroll;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overf-scroll\@md {
    overflow: scroll;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overf-scroll\@sm {
    overflow: scroll;
  }
}
@media (max-width: 575px) {
  .u-overf-scroll\@xs {
    overflow: scroll;
  }
}

.u-overx-hidden {
  overflow-x: hidden;
}
@media (min-width: 1400px) {
  .u-overx-hidden\@xxl {
    overflow-x: hidden;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overx-hidden\@xl {
    overflow-x: hidden;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overx-hidden\@lg {
    overflow-x: hidden;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overx-hidden\@md {
    overflow-x: hidden;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overx-hidden\@sm {
    overflow-x: hidden;
  }
}
@media (max-width: 575px) {
  .u-overx-hidden\@xs {
    overflow-x: hidden;
  }
}

.u-overx-auto {
  overflow-x: auto;
}
@media (min-width: 1400px) {
  .u-overx-auto\@xxl {
    overflow-x: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overx-auto\@xl {
    overflow-x: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overx-auto\@lg {
    overflow-x: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overx-auto\@md {
    overflow-x: auto;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overx-auto\@sm {
    overflow-x: auto;
  }
}
@media (max-width: 575px) {
  .u-overx-auto\@xs {
    overflow-x: auto;
  }
}

.u-overx-visible {
  overflow-x: visible;
}
@media (min-width: 1400px) {
  .u-overx-visible\@xxl {
    overflow-x: visible;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overx-visible\@xl {
    overflow-x: visible;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overx-visible\@lg {
    overflow-x: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overx-visible\@md {
    overflow-x: visible;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overx-visible\@sm {
    overflow-x: visible;
  }
}
@media (max-width: 575px) {
  .u-overx-visible\@xs {
    overflow-x: visible;
  }
}

.u-overx-scroll {
  overflow-x: scroll;
}
@media (min-width: 1400px) {
  .u-overx-scroll\@xxl {
    overflow-x: scroll;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overx-scroll\@xl {
    overflow-x: scroll;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overx-scroll\@lg {
    overflow-x: scroll;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overx-scroll\@md {
    overflow-x: scroll;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overx-scroll\@sm {
    overflow-x: scroll;
  }
}
@media (max-width: 575px) {
  .u-overx-scroll\@xs {
    overflow-x: scroll;
  }
}

.u-overy-hidden {
  overflow-y: hidden;
}
@media (min-width: 1400px) {
  .u-overy-hidden\@xxl {
    overflow-y: hidden;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overy-hidden\@xl {
    overflow-y: hidden;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overy-hidden\@lg {
    overflow-y: hidden;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overy-hidden\@md {
    overflow-y: hidden;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overy-hidden\@sm {
    overflow-y: hidden;
  }
}
@media (max-width: 575px) {
  .u-overy-hidden\@xs {
    overflow-y: hidden;
  }
}

.u-overy-auto {
  overflow-y: auto;
}
@media (min-width: 1400px) {
  .u-overy-auto\@xxl {
    overflow-y: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overy-auto\@xl {
    overflow-y: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overy-auto\@lg {
    overflow-y: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overy-auto\@md {
    overflow-y: auto;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overy-auto\@sm {
    overflow-y: auto;
  }
}
@media (max-width: 575px) {
  .u-overy-auto\@xs {
    overflow-y: auto;
  }
}

.u-overy-visible {
  overflow-y: visible;
}
@media (min-width: 1400px) {
  .u-overy-visible\@xxl {
    overflow-y: visible;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overy-visible\@xl {
    overflow-y: visible;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overy-visible\@lg {
    overflow-y: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overy-visible\@md {
    overflow-y: visible;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overy-visible\@sm {
    overflow-y: visible;
  }
}
@media (max-width: 575px) {
  .u-overy-visible\@xs {
    overflow-y: visible;
  }
}

.u-overy-scroll {
  overflow-y: scroll;
}
@media (min-width: 1400px) {
  .u-overy-scroll\@xxl {
    overflow-y: scroll;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-overy-scroll\@xl {
    overflow-y: scroll;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-overy-scroll\@lg {
    overflow-y: scroll;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-overy-scroll\@md {
    overflow-y: scroll;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-overy-scroll\@sm {
    overflow-y: scroll;
  }
}
@media (max-width: 575px) {
  .u-overy-scroll\@xs {
    overflow-y: scroll;
  }
}

/* UTILITIES EXTRA */
.u-opacity-0 {
  opacity: 0.0;
}

.u-opacity-25 {
  opacity: 0.25;
}

.u-opacity-5 {
  opacity: 0.5;
}

.u-opacity-75 {
  opacity: 0.75;
}

.u-opacity-1 {
  opacity: 0.1;
}

.u-ellipsis, .u-ellipsis--line {
  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.u-ellipsis--line {
  -webkit-line-clamp: 1;
}

.u-state-disable {
  opacity: 0.5;
}

.u-flex-fill {
  flex: 1 1 auto;
}

.u-flex-grow-0 {
  flex-grow: 0;
}

.u-flex-shrink-0 {
  flex-shrink: 0;
}

.u-flex-grow-1 {
  flex-grow: 1;
}

.u-flex-shrink-1 {
  flex-shrink: 1;
}

.u-indent-1 {
  text-indent: 0.5rem;
}
@media (min-width: 1400px) {
  .u-indent-1\@xxl {
    text-indent: 0.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-indent-1\@xl {
    text-indent: 0.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-indent-1\@lg {
    text-indent: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-indent-1\@md {
    text-indent: 0.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-indent-1\@sm {
    text-indent: 0.5rem;
  }
}
@media (max-width: 575px) {
  .u-indent-1\@xs {
    text-indent: 0.5rem;
  }
}

.u-indent-2 {
  text-indent: 1rem;
}
@media (min-width: 1400px) {
  .u-indent-2\@xxl {
    text-indent: 1rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-indent-2\@xl {
    text-indent: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-indent-2\@lg {
    text-indent: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-indent-2\@md {
    text-indent: 1rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-indent-2\@sm {
    text-indent: 1rem;
  }
}
@media (max-width: 575px) {
  .u-indent-2\@xs {
    text-indent: 1rem;
  }
}

.u-indent-3 {
  text-indent: 1.5rem;
}
@media (min-width: 1400px) {
  .u-indent-3\@xxl {
    text-indent: 1.5rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-indent-3\@xl {
    text-indent: 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-indent-3\@lg {
    text-indent: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-indent-3\@md {
    text-indent: 1.5rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-indent-3\@sm {
    text-indent: 1.5rem;
  }
}
@media (max-width: 575px) {
  .u-indent-3\@xs {
    text-indent: 1.5rem;
  }
}

.u-indent-4 {
  text-indent: 2rem;
}
@media (min-width: 1400px) {
  .u-indent-4\@xxl {
    text-indent: 2rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .u-indent-4\@xl {
    text-indent: 2rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .u-indent-4\@lg {
    text-indent: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .u-indent-4\@md {
    text-indent: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .u-indent-4\@sm {
    text-indent: 2rem;
  }
}
@media (max-width: 575px) {
  .u-indent-4\@xs {
    text-indent: 2rem;
  }
}

@media (min-width: 1200px) {
  .u-size-content\@xl {
    max-width: fit-content;
    width: fit-content;
  }
}

.u-gap-2 {
  gap: 0.125rem;
}

.u-gap-4 {
  gap: 0.25rem;
}

.u-gap-8 {
  gap: 0.5rem;
}

.u-gap-12 {
  gap: 0.75rem;
}

.u-gap-14 {
  gap: 0.875rem;
}

.u-gap-16 {
  gap: 1rem;
}

.u-gap-24 {
  gap: 1.5rem;
}

.u-rotate-180 {
  transform: rotate(180deg);
}

.u-block-events {
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}

.u-z-max {
  z-index: 10;
}

/* @import 'input'; */
/* BLOCK */
.e-btn, .e-btn--tertiary, .e-btn--secondary, .e-btn--primary {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  color: #FFF;
  line-height: 1.25rem;
  font-size: 1rem;
  opacity: 0.9;
  padding: 0.3rem 0.6rem;
  /*
    STATES
  */
  /*
    ELEMENTS
  */
  /* 
    MODIFIERS
  */
}
.e-btn:hover, .e-btn--tertiary:hover, .e-btn--secondary:hover, .e-btn--primary:hover {
  color: #FFF;
  opacity: 1;
}
.e-btn:disabled, .e-btn--tertiary:disabled, .e-btn--secondary:disabled, .e-btn--primary:disabled {
  cursor: not-allowed;
}
.e-btn__icon--start {
  margin-right: 0.625rem;
}
.e-btn__icon--end {
  margin-left: 0.625rem;
}
.e-btn--primary {
  background-color: #001871;
  border-color: #001871;
}
.e-btn--primary:active:not(:disabled) {
  background-color: #1352DE;
  border-color: #1352DE;
}
.e-btn--secondary {
  background-color: #FFF;
  border-color: #D0D6D3;
  color: #001871;
}
.e-btn--secondary:active {
  background-color: #5D738D;
  color: #FFF;
}
.e-btn--secondary:hover {
  background-color: #FFF;
  color: #1352DE;
}
.e-btn--tertiary {
  background-color: #1352DE;
  border-color: #1352DE;
}
.e-btn--tertiary:active {
  background-color: #347DBB;
}
.e-btn--transparent {
  background-color: transparent;
}
.e-btn--extrap {
  padding: 0.625rem 1.5rem;
}
.e-btn--rounded {
  border-radius: 6.25rem;
}
.e-btn:disabled, .e-btn--primary:disabled, .e-btn--secondary:disabled, .e-btn--tertiary:disabled {
  opacity: 0.4;
}

.e-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.9375rem;
  position: relative;
  /* Firefox, ios, edge, opera */
}
.e-field__label {
  color: #001871;
  line-height: 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.e-field__input {
  appearance: none;
  background: #FFF;
  background-color: #FFF;
  border: 0.0625rem solid #B9C6D4;
  border-radius: 0.25rem;
  color: #333333;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  width: 100%;
}
.e-field__input[type=number], .e-field__input[type=date] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  background: #FFF;
  background-color: #FFF;
}
.e-field__input:focus, .e-field__input:active, .e-field__input:focus-visible {
  border-color: #337AB7;
  outline: #337AB7;
}
.e-field__input:disabled {
  background-color: #F2F6FC;
  border-color: #B9C6D4;
  color: #5D738D;
}
.e-field input[type=number], .e-field input[type=date], .e-field input[type=text] {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
}
.e-field__error {
  color: #BF0019;
  font-size: 0.75rem;
}
.e-field__pwicon {
  bottom: 0;
  margin: auto 0;
  position: absolute;
  right: 0.5rem;
  top: 0;
}

/* BLOCK */
.e-label {
  color: #707070;
  font-size: 1rem;
  font-weight: 300;
}

.e-pill, .e-pill-primary {
  background-color: #FFF;
  border-radius: 0.625rem;
  border-style: solid;
  border-width: 0.0625rem;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}
.e-pill-primary {
  border-color: #001871;
  color: #001871;
}
.e-pill-primary--active {
  background-color: #EBF1FB;
  border-color: #1352DE;
  color: #1352DE;
}

/* BLOCK */
.e-radio {
  display: flex;
  align-items: center;
  justify-content: start;
  /*
    ELEMENTS
  */
}
.e-radio__input {
  display: none;
}
.e-radio__btn {
  border: 0.0625rem solid #001871;
  border-radius: 50%;
  height: 1.3125rem;
  position: relative;
  width: 1.3125rem;
}
.e-radio__btn::after {
  width: 0.6875rem;
  height: 0.6875rem;
  min-height: 0.6875rem;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #001871;
  border-radius: 50%;
  content: "";
  display: none;
}
.e-radio__btn.active:after {
  display: block;
}
.e-radio__label {
  color: #000000;
  margin-left: 0.3125rem;
}

.e-searchbar {
  align-items: center;
  border: 0.0625rem solid #707070;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
}
.e-searchbar:hover, .e-searchbar:focus-within {
  border-color: #001871;
  border-width: 0.125rem;
}
.e-searchbar:hover .icon-close, .e-searchbar:focus-within .icon-close {
  visibility: visible;
  color: #001871;
}
.e-searchbar__icon {
  margin: 0.5rem 0.625rem;
}
.e-searchbar__input {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}
.e-searchbar__input .input {
  appearance: none;
  background: transparent;
  border: 0;
  font-size: 1rem;
  width: 100%;
}
.e-searchbar__input .input:focus {
  outline: none;
}
.e-searchbar__input .input:not([value=""]) .e-searchbar {
  border-color: #001871;
}
.e-searchbar__input .icon-close {
  background: #FFF;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  visibility: hidden;
  cursor: pointer;
}

.c-accordion {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 0.125rem solid #C4C4C4;
  flex-direction: column;
  overflow-y: hidden;
  padding: 1.375rem 0;
  transition: height 1s ease-in;
  text-align: left;
  width: 100%;
}
.c-accordion:first-child {
  border-top: 0.125rem solid #C4C4C4;
}
.c-accordion__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}
.c-accordion__body {
  font-size: 1rem;
}
.c-accordion__title {
  color: #001871;
  font-size: 1.75rem;
}
@media (max-width: 575px) {
  .c-accordion__title {
    font-size: 1.125rem;
  }
}
.c-accordion__title--notexpanded {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  max-width: 90%;
  overflow: hidden;
  text-overflow: hidden;
}
.c-accordion__arrow {
  transition: transform 0.3s ease-in-out;
}
.c-accordion__arrow--rotated {
  transform: rotate(180deg);
}
.c-accordion--expanded {
  height: auto;
}

.c-breadcrumb {
  background: transparent;
  margin: 0;
  padding-bottom: 0.25rem;
}
.c-jumbotron, .c-jumbotron-dashed, .c-jumbotron-error, .c-jumbotron-primary {
  background-color: #F2F6FC;
  color: #337AB7;
  font-size: 0.75rem;
  padding: 2.25rem;
  width: 100%;
}
.c-jumbotron-primary {
  background-color: #EBF1FB;
  color: #1352DE;
}
.c-jumbotron-error {
  background-color: #F2F2F2;
  color: #BF0019;
}
.c-jumbotron-dashed {
  background-color: transparent;
  border: 0.0625rem dashed #001871;
  color: #001871;
}
.c-jumbotron--pill {
  padding: 0.25rem 0.5rem;
}

/* CHEATSHEET */ /* #region USE */
/* #endregion USE */
/* #region BLOCK */
.c-modal, .c-modal--iframe {
  /* #region VARIABLES */
  --align-wrapper: flex-start;
  --bg: #00000050;
  --bg-wrapper: #FFF;
  --height: 100vh;
  --h-wrapper: 100%;
  --h-max-wrapper: 100%;
  --justify-wrapper: flex-end;
  --width: 100vw;
  --w-wrapper: 70%;
  /* #endregion VARIABLES */
  width: var(--width);
  height: var(--height);
  min-height: var(--height);
  display: flex;
  align-items: var(--align-wrapper);
  justify-content: var(--justify-wrapper);
  background-color: var(--bg);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  /* #region ELEMENTS */
  /* #endregion ELEMENTS */
  /* #region MODIFIERS */
  /* #endregion MODIFIERS */
}
.c-modal__wrapper, .c-modal__wrapper--toast {
  width: var(--w-wrapper);
  height: var(--h-wrapper);
  min-height: var(--h-wrapper);
  background-color: var(--bg-wrapper);
  padding: 14;
}
.c-modal__wrapper--toast {
  --h-wrapper: auto;
  --h-max-wrapper: 90%;
  --w-wrapper: fit-content;
  max-width: 70%;
}
@media (max-width: 768px) {
  .c-modal__wrapper--toast {
    max-width: 90%;
  }
}
@media (min-width: 1200px) {
  .c-modal__wrapper--toast {
    max-width: 50%;
  }
}
.c-modal--iframe {
  --width: 100%;
  --height: 100%;
}
.c-modal--center {
  --align-wrapper: center;
  --justify-wrapper: center;
}

/* #endregion BLOCK */
.c-panel {
  background-color: #F2F6FC;
  padding: 1rem 2rem;
  width: fit-content;
}
.c-panel__title .icon {
  font-size: 2.4375rem;
  margin: 1rem;
}

.c-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.c-switch__checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #D0D6D3;
  border-radius: 6.25rem;
  color: #FFF;
  cursor: pointer;
  height: 1.625rem;
  padding: 0.25rem;
  position: relative;
  width: 3.75rem;
}
.c-switch__checkbox .c-switch__on {
  opacity: 0;
}
.c-switch__checkbox .c-switch__off {
  opacity: 1;
}
.c-switch__checkbox--on {
  background-color: #001871;
}
.c-switch__checkbox--on .c-switch__off {
  opacity: 0;
}
.c-switch__checkbox--on .c-switch__on {
  opacity: 1;
}
.c-switch__thumb-wrap {
  width: 0;
  height: 0;
  min-height: 0;
  left: 0.875rem;
  overflow: visible;
  position: absolute;
  top: 50%;
  transition: left 200ms ease-in-out;
}
.c-switch__thumb-wrap--on {
  left: calc(100% - 14px);
}
.c-switch__thumb {
  width: 1.3125rem;
  height: 1.3125rem;
  min-height: 1.3125rem;
  background-color: #FFF;
  border-radius: 6.25rem;
  position: absolute;
  transform: translate(-50%, -50%);
}
.c-switch__label {
  color: #A8A8A8;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.c-switch__label--after {
  margin-left: 0.5rem;
}
.c-switch__label--on {
  color: #333333;
}
.c-switch__input {
  display: none;
}

.c-tab--w100 {
  width: 100%;
}
.c-tab--between {
  display: flex;
  justify-content: space-between;
}
.c-tab__caption {
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  font-weight: 600;
  margin: 0 16px;
  opacity: 0.4;
  padding: 1rem 1.625rem;
  position: relative;
}
.c-tab__caption .bar-active {
  background-color: #001871;
  border-radius: 1.5625rem;
  bottom: 0;
  display: none;
  height: 0.125rem;
  left: 0;
  position: absolute;
  width: 100%;
}
@keyframes slide-right {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0);
  }
}
.c-tab__caption.active {
  opacity: 1;
}
.c-tab__caption.active .bar-active {
  animation: slide-right 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  display: block;
}

.c-toast {
  background-color: #FFF;
  border-radius: 0.25rem;
  /* border-left: calcrem(4) solid; */
  filter: drop-shadow(0.125rem 0.375rem 0.5625rem rgba(0, 0, 0, 0.18));
  height: fit-content;
  opacity: 1;
  padding: 1rem 1.5rem;
  position: absolute;
  transition: opacity 0.5s linear;
  visibility: visible;
  width: 29.875rem;
  z-index: 3;
}
.c-toast.success {
  border-left-color: #62DA70;
}
.c-toast.error {
  border-left-color: #BF0019;
}
.c-toast .icon-Close {
  position: absolute;
  top: -18px;
  right: -345px;
}
.c-toast.bmiddle {
  bottom: 5%;
  right: 0;
}
.c-toast.closed {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 1s linear;
}

.c-tooltip {
  background-color: #FFF;
  border: 0.0625rem solid #001871;
  border-radius: 0.1875rem;
  color: #000000;
  padding: 0.375rem 0.375rem;
  position: absolute;
  text-align: center;
  visibility: hidden;
  width: 12.5rem;
  z-index: 1;
}
.c-tooltip::after {
  content: "";
  position: absolute;
  border-width: 0.3125rem;
  border-style: solid;
}
.c-tooltip--bottom {
  margin-left: -9.375rem;
  top: 80%;
  left: 10%;
}
.c-tooltip--bottom::after {
  margin-left: -0.3125rem;
  bottom: 100%;
  left: 79%;
  border-color: transparent transparent #001871 transparent;
}
.c-tooltip--top {
  margin-left: -3.75rem;
  bottom: 150%;
  left: 50%;
}
.c-tooltip--top::after {
  margin-left: -0.3125rem;
  top: 100%;
  left: 50%;
  border-color: #001871 transparent transparent transparent;
}
.c-tooltip strong {
  color: #F2F2F2;
}

.c-nav {
  display: flex;
  width: fit-content;
}
.c-nav__item, .c-nav__item--sub {
  align-items: center;
  display: flex;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0.5rem 0.375rem;
  margin: 0 1rem;
}
.c-nav__item:hover, .c-nav__item--sub:hover {
  text-decoration: underline;
}
.c-nav__item--sub:hover {
  text-decoration: none;
}

.p-flexcc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-flexcs {
  display: flex;
  align-items: center;
  justify-content: start;
}
.p-flexce {
  display: flex;
  align-items: center;
  justify-content: end;
}
.p-flexcb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-flexca {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.p-flexci {
  display: flex;
  align-items: center;
  justify-content: initial;
}
.p-flexsc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.p-flexss {
  display: flex;
  align-items: flex-start;
  justify-content: start;
}
.p-flexse {
  display: flex;
  align-items: flex-start;
  justify-content: end;
}
.p-flexsb {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.p-flexsa {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.p-flexsi {
  display: flex;
  align-items: flex-start;
  justify-content: initial;
}
.p-flexec {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.p-flexes {
  display: flex;
  align-items: flex-end;
  justify-content: start;
}
.p-flexee {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}
.p-flexeb {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.p-flexea {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.p-flexei {
  display: flex;
  align-items: flex-end;
  justify-content: initial;
}
.p-flexic {
  display: flex;
  align-items: initial;
  justify-content: center;
}
.p-flexis {
  display: flex;
  align-items: initial;
  justify-content: start;
}
.p-flexie {
  display: flex;
  align-items: initial;
  justify-content: end;
}
.p-flexib {
  display: flex;
  align-items: initial;
  justify-content: space-between;
}
.p-flexia {
  display: flex;
  align-items: initial;
  justify-content: space-around;
}
.p-flexii {
  display: flex;
  align-items: initial;
  justify-content: initial;
}

.u-fwrap-wrap {
  flex-wrap: wrap;
}

.o-container {
  margin: 0 auto;
  max-width: 100%;
}
@media (min-width: 576px) and (max-width: 767px) {
  .o-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .o-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .o-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .o-container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .o-container {
    max-width: 1320px;
  }
}
@media (min-width: 1700px) {
  .o-container {
    max-width: 66.66666667%;
  }
}
.o-container-lg {
  max-width: 100%;
}
@media (min-width: 1400px) {
  .o-container-lg {
    max-width: 1320px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .o-container-lg {
    max-width: 1140px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .o-container-lg {
    max-width: 960px;
  }
}

::ng-deep .cdk-overlay-container,
::ng-deep .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
::ng-deep .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
::ng-deep .cdk-overlay-pane {
  background-color: #FFF;
  border: 0.0625rem solid #F2F2F2;
  position: absolute;
  margin-top: 2rem;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}