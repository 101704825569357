
html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

select,
button {
  cursor: pointer;
}

input {
  background-image: none;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}