

@mixin face-property($weight, $family: $font-family) {
  font-family: $family;
  font-weight: $weight;
  font-style: 300;
  font-strecht: condensed;
};


@font-face {
  src: (
    url("../../assets/fonts/typo/source-sanspro-light.ttf") format('embedded-opentype'),
    url("../../assets/fonts/typo/source-sanspro-light.ttf") format("truetype"),
  );

  @include face-property(300);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/source-sanspro-regular.ttf") format('embedded-opentype'),
    url("../../assets/fonts/typo/source-sanspro-regular.ttf") format("truetype"),
  );

  @include face-property(400);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/source-sanspro-semibold.ttf") format('embedded-opentype'),
    url("../../assets/fonts/typo/source-sanspro-semibold.ttf") format("truetype"),
  );

  @include face-property(500);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/source-sanspro-bold.ttf") format('embedded-opentype'),
    url("../../assets/fonts/typo/source-sanspro-bold.ttf") format("truetype"),
  );

  @include face-property(600);
}

/* FONT FAMILY FUTURA */
@font-face {
  src: (
    url("../../assets/fonts/typo/pt-aigfutura-book-webfont.ttf") format('embedded-opentype'),
    url("../../assets/fonts/typo/pt-aigfutura-book-webfont.ttf") format("truetype"),
  );

  @include face-property(normal, $font-family2);
}

@font-face {
  src: (
    url("../../assets/fonts/typo/pt-aigfutura-medium-webfont.ttf") format('embedded-opentype'),
    url("../../assets/fonts/typo/pt-aigfutura-medium-webfont.ttf") format("truetype"),
  );

  @include face-property(500, $font-family2);
}


@font-face {
  src: (
    url("../../assets/fonts/typo/pt-aigfutura-bold-webfont.ttf") format('embedded-opentype'),
    url("../../assets/fonts/typo/pt-aigfutura-bold-webfont.ttf") format("truetype"),
  );

  @include face-property(600, $font-family2);
}