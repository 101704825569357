.e-field {

  $self: &;

  display: flex;
  flex-direction: column;
  margin-bottom: calcrem(15);
  position: relative;
  

  &__label {
    color: $c-primary;
    line-height: calcrem(20);
    font-size: calcrem(16);
    font-weight: 400;
    margin-bottom: calcrem(4);
  }

  &__input {
    
    appearance:none;
    background: $c-white;
    background-color: $c-white;
    border: calcrem(1) solid $c-primary-2;
    border-radius: calcrem(4);
    color: $c-secondary;
    font-size: calcrem(16);
    padding: calcrem(16) calcrem(8);
    width: 100%;

    &[type="number"], &[type="date"] {
      -moz-appearance   : textfield;
      -webkit-appearance: none;
      background: $c-white;
    background-color: $c-white;
    }
     &:focus, &:active, &:focus-visible {
      border-color: $c-primary-3;
      outline: $c-primary-3;
    }

    &:disabled {
      background-color: $c-disabled1;
      border-color: $c-primary-2;
      color: $c-selected2;
    }
  }

  /* Firefox, ios, edge, opera */
  input[type=number], input[type=date], input[type=text] {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
  }

  &__error {
    color: $c-warning;
    font-size: calcrem(12);
  }

  &__pwicon {
    bottom: 0;
    margin: auto 0;
    position: absolute;
    right: calcrem(8);
    top: 0;
  }
  
}