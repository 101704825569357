.c-switch {

  $self: &;

  @include flex-align(center, flex-start);

  width: 100%;

  &__checkbox {

    @include flex-align(center, space-between);

    background-color: $c-comp3;
    border-radius: calcrem(100);
    color: $c-white;
    cursor: pointer;
    height: calcrem(26);
    padding: calcrem(4);
    position: relative;
    width: calcrem(60);

    #{$self}__on {
      opacity: 0;
    }

    #{$self}__off {
      opacity: 1;
    }

    &--on {
      background-color: $c-primary;

      #{$self}__off {
        opacity: 0
      }

      #{$self}__on {
        opacity: 1
      }
    }
  }

  &__thumb-wrap {
    @include setWidthAndHeight(0, 0);
    
    left: calcrem(14);
    overflow: visible;
    position: absolute;
    top: 50%;
    transition: left 200ms ease-in-out;

    &--on {
      left: calc(100% - 14px);
    }
  }

  &__thumb {
    @include setWidthAndHeight(calcrem(21), calcrem(21));
    background-color: $c-white;
    border-radius: calcrem(100);
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &__label {
    color: $c-grey-4;
    font-size: calcrem($f-14);
    font-weight: 400;
    line-height: calcrem($f-20);

    &--after {
      margin-left: calcrem(8);
    }

    &--on {
      color: $c-secondary;
    }
  }

  &__input {
    display: none;
  }
}