/* #region USE */
@use "../00.settings/variables" as v;
@use "../02.tools/functions" as f;
@use "../02.tools/media-queries" as mq;
/* #endregion USE */

/* #region BLOCK */
.c-modal {

  /* #region VARIABLES */
  --align-wrapper: flex-start;
  --bg: #{v.$c-black}50;
  --bg-wrapper: #{v.$c-white};
  --height: 100vh;
  --h-wrapper: 100%;
  --h-max-wrapper: 100%;
  --justify-wrapper: flex-end;
  --width: 100vw;
  --w-wrapper: 70%;
  /* #endregion VARIABLES */

  @include f.setWidthAndHeight(var(--width), var(--height));
  @include f.flex-align(var(--align-wrapper), var(--justify-wrapper));

  background-color: var(--bg);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  /* #region ELEMENTS */
  // el wrapper por defecto se posiciona en la derecha
  &__wrapper {
    @include f.setWidthAndHeight(var(--w-wrapper), var(--h-wrapper));

    background-color: var(--bg-wrapper);
    padding: v.$f-14;

    &--toast {
      --h-wrapper: auto;
      --h-max-wrapper: 90%;
      --w-wrapper: fit-content;

      @extend .c-modal__wrapper;

      max-width: 70%;

      @include mq.max-width($bp-md) {
        max-width: 90%;
      }

      @include mq.min-width($bp-xl) {
        max-width: 50%;
      }

    }

  }
  /* #endregion ELEMENTS */

  /* #region MODIFIERS */
  &--iframe {
    --width: 100%;
    --height: 100%;

    @extend .c-modal;
  }

  &--center {
    --align-wrapper: center;
    --justify-wrapper: center;
  }

  /* #endregion MODIFIERS */

}
/* #endregion BLOCK */
