// TOAST

.c-toast {
  // animation: fade-in 1s ease-in;
  background-color: $c-white;
  border-radius: calcrem(4);
  /* border-left: calcrem(4) solid; */
  filter: drop-shadow(calcrem(2) calcrem(6) calcrem(9) rgba($c-black, 0.18));
  height: fit-content;
  opacity: 1;
  padding: calcrem(16) calcrem(24); //calcrem(8)
  position: absolute;
  transition: opacity .5s linear;
  visibility: visible;
  width: calcrem(478);
  z-index: 3;

  &.success {
    border-left-color: $c-success;
  }

  &.error {
    border-left-color: $c-warning;
  }

  .icon-Close{
    position: absolute;
    top: -18px;
    right: -345px;
  }


  &.bmiddle {
    bottom: 5%; //bottom: 15%;
    right: 0; //left: 50%;
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 1s linear;
  }
}