.c-breadcrumb {
  
    background: transparent;
    margin: 0;
    padding-bottom: calcrem(4);

    &--path {
      
    }

}